import * as React from "react"

import { debounce } from "lodash-es"
import Select from "react-select"

import { PaginationBar } from "../PaginationBar"
import { RailsForm } from "../RailsForm"
import { appClient } from "~/src/lib/appClients"
import { PageMeta, pageMetaFromHeader } from "~/src/lib/page"

import "./TeamForm.scss"

const options = (groups: Array<StoreProductGroup>) =>
  groups.map((group) => ({
    value: group.id,
    label: group.name || "No Name",
  }))

type StoreUserListItem = {
  id: number
  name: string
  email: string
}

type IdHash = {
  [id: number]: number
}

type StoreUserData = {
  data: Array<StoreUserListItem>
  meta: PageMeta
}

type Team = {
  created_at: string
  default: boolean
  id: number
  name: string
  store_id: number
  updated_at: string
}

type StoreProductGroup = {
  id: number
  name: string
}

type PreferenceCollector = {
  id: number
  name: string
}

type TeamFormProps = {
  team: Team
  storeProductGroupOptions: Array<StoreProductGroup>
  preferenceCollectorOptions: Array<PreferenceCollector>
  storeUsers: StoreUserData
  formPath: string
  teamsPath: string
  userDataPath: string
  isDefault: boolean
  initialPageMeta: PageMeta
  teamMembers: IdHash
  teamProductGroupOptions: Array<StoreProductGroup>
  teamPreferenceCollectorOptions: Array<PreferenceCollector>
  creativePartner: string
}

export function TeamForm({
  team,
  formPath,
  teamsPath,
  userDataPath,
  storeProductGroupOptions,
  teamProductGroupOptions,
  preferenceCollectorOptions,
  teamPreferenceCollectorOptions,
  initialPageMeta,
  teamMembers,
  isDefault,
  creativePartner,
}: TeamFormProps) {
  const [initialFetchComplete, setInitialFetchComplete] = React.useState(false)
  const [selectedUsers, setSelectedUsers] = React.useState<IdHash>(teamMembers)
  const [storeUsers, setStoreUsers] = React.useState<Array<StoreUserListItem>>([])
  const [pageMeta, setPageMeta] = React.useState<PageMeta>(initialPageMeta)
  const [searchQuery, setSearchQuery] = React.useState("")

  const setPage = (page: number) => {
    setPageMeta({
      ...pageMeta,
      currentPage: page,
    })
  }

  const handleChange = (e) => {
    const userId = e.target.value
    const checked = e.target.checked
    if (checked) {
      setSelectedUsers({ ...selectedUsers, [userId]: userId })
    } else {
      const selected = { ...selectedUsers }
      delete selected[userId]
      setSelectedUsers(selected) // remove from selectedUsers
    }
  }

  // identity stable debounced function
  const handleDebouncedSearch = React.useRef(
    debounce((e) => {
      setSearchQuery(e.target.value)
    }, 250)
  )

  React.useEffect(() => {
    const controller = new AbortController()

    appClient
      .get(`${userDataPath}?page=${pageMeta.currentPage}&q=${searchQuery}`, {
        signal: controller.signal,
      })
      .then((res) => {
        const storeUserData = res.data as StoreUserData
        setStoreUsers(storeUserData.data)
        setPageMeta(pageMetaFromHeader(res.headers))
        setInitialFetchComplete(true)
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          // if the request was aborted, do nothing
          return
        }

        // re-throw error so it can be handled by logging
        throw err
      })

    return () => {
      controller.abort()
    }
  }, [pageMeta.currentPage, searchQuery])

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid var(--neutral-color)" : "1px solid var(--light-border-color)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid var(--neutral-color)",
        boxShadow: "none",
      },
    }),
  }

  function renderPreferenceCollectors() {
    return (
      <div className="team-form__section">
        <label htmlFor="preference-collectors" className="team-form__section-label">
          Preferred Gifts:
          <span className="team-form__section-description">
            Members of this team will be able to add recipients and see details of selected preferred gifts
          </span>
        </label>
        <Select
          styles={style}
          className="teams-form__select"
          id="preference-collectors"
          defaultValue={options(teamPreferenceCollectorOptions)}
          name="team[preference_collector_ids][]"
          isMulti
          isSearchable
          options={options(preferenceCollectorOptions)}
        />
      </div>
    )
  }

  return initialFetchComplete ? (
    <RailsForm action={formPath} method={team.id ? "put" : "post"} className="team-form">
      <div className="team-form__section section-row">
        <label htmlFor="name" className="team-form__section-label">
          Name:
        </label>
        <div className="team-form__section-input">
          <input id="name" type="text" name="team[name]" defaultValue={team.name} autoComplete="off" />
        </div>
      </div>
      {renderPreferenceCollectors()}
      <div className="team-form__section">
        <label htmlFor="product-groups" className="team-form__section-label">
          Collections:
          <span className="team-form__section-description">
            Team members will see all products in the below product collections. To update the products in each
            collection, contact your Creative Partner at {<a href={`mailto:${creativePartner}`}>{creativePartner}</a>}.
          </span>
        </label>
        <Select
          styles={style}
          className="teams-form__select"
          id="product-groups"
          defaultValue={options(teamProductGroupOptions)}
          name="team[store_product_group_ids][]"
          isMulti
          isSearchable
          options={options(storeProductGroupOptions)}
        />
      </div>
      <div className="team-form__users team-form__section">
        <div className="team-form__section-label">
          Users {Object.keys(teamMembers).length ? `(${Object.keys(teamMembers).length} team members)` : ""}
          <span className="team-form__section-description">
            Team members will see the products in the selected collections.
          </span>
        </div>
        <div className="user-search">
          <input
            id="user-search"
            aria-label="Search users"
            type="text"
            name="search"
            placeholder="Search by Name or Email"
            autoComplete="off"
            onChange={handleDebouncedSearch.current}
            className="users-list__user-search__input"
          />
        </div>
        <div className="users-list">
          {storeUsers.length > 0 ? (
            storeUsers.map((user) => (
              <div key={user.id} className="users-list__list-item">
                <input
                  className="users-list__checkbox"
                  onChange={handleChange}
                  type="checkbox"
                  id={`check-${user.id}`}
                  value={user.id}
                  disabled={isDefault}
                  // if a user is already selected, check the box
                  checked={!!selectedUsers[user.id]}
                />
                <label htmlFor={`check-${user.id}`} className="users-list__list-item__label">
                  {user.name ? (
                    <>
                      <span>{user.name}</span>
                      <span>-</span>
                    </>
                  ) : null}
                  <span>{user.email}</span>
                </label>
              </div>
            ))
          ) : (
            <div className="users-list__no-results">No Results...</div>
          )}
        </div>
        {Object.values(selectedUsers).map((id) => (
          <input key={id} type="hidden" value={id} name="team[store_user_ids][]" />
        ))}
        <PaginationBar setPage={setPage} {...pageMeta} perPage={storeUsers.length} />
      </div>
      <div className="teams-form__btn-group">
        <input className="button" type="submit" value="Submit" />
        <a className="button grey" href={teamsPath}>
          Cancel
        </a>
      </div>
    </RailsForm>
  ) : null
}
