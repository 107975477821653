import React, { useState } from "react"

import { twMerge } from "tailwind-merge"

import { dataFlag } from "~/src/lib/jsx"
import { type Color as BaseColor } from "~/src/types/color"

export type Color = BaseColor & { fallbackImageUrl: string }

export type ColorSwatchProps = {
  colors: Color[]
  className?: string
  onClick?: (color: Color, index?: number) => void
  defaultColor?: Color
}

export function ColorSwatch(props: ColorSwatchProps) {
  const { colors, className, onClick, defaultColor } = props

  if (colors.length === 0) {
    return null
  }

  const initialColor =
    defaultColor && colors.some((color) => color.colorCode === defaultColor.colorCode) ? defaultColor : colors[0]

  const [selectedColor, setSelectedColor] = useState<Color>(initialColor)

  const handleColorClick = (color: Color, index: number) => {
    setSelectedColor(color)
    onClick?.(color, index)
  }

  return (
    <section className={twMerge("mb-3 mt-3 flex flex-col gap-2", className)}>
      <h1 className="text-left text-base font-medium leading-6 text-gray-900">
        Color: <span className="font-normal text-gray-600">{selectedColor?.colorName || ""}</span>
      </h1>
      <section className="flex flex-wrap items-center gap-2">
        {colors.map((color, index) => {
          const isSelected =
            color.colorCode === selectedColor?.colorCode && color.colorName === selectedColor?.colorName
          return (
            <div
              key={index}
              className={
                "flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2 border-transparent data-[selected]:border-gray-900"
              }
              {...dataFlag(isSelected, "selected")}
              onClick={() => handleColorClick(color, index)}
              title={color.colorName}
              style={{ boxSizing: "border-box" }}
            >
              {color.colorCode ? (
                <div
                  className="h-6 w-6 rounded-full border border-gray-300"
                  style={{ backgroundColor: color.colorCode }}
                />
              ) : (
                <div className="flex h-6 w-6 items-center justify-center overflow-hidden rounded-full border border-gray-300">
                  <img
                    className="blur-sm"
                    src={color.fallbackImageUrl}
                    style={{
                      width: "400%",
                      height: "400%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </section>
    </section>
  )
}
