import * as React from "react"

import { twMerge } from "tailwind-merge"

import { inputClassnames } from "~/src/components/StoreKitInstances/StoreKitInstanceForm"
import { StoreProductField, StoreProductFieldType } from "~/src/serializedRecords"

interface KitItemProductFieldProps {
  defaultValue?: string
  field: StoreProductField
  inputName: string
}

export function KitItemProductField({
  defaultValue,
  field: { fieldType, label, limit },
  inputName,
}: KitItemProductFieldProps) {
  let inputField: JSX.Element

  switch (fieldType) {
    case StoreProductFieldType.file: {
      inputField = <input className={twMerge(inputClassnames)} name={inputName} type="file" />

      break
    }
    case StoreProductFieldType.text: {
      inputField = (
        <textarea name={inputName} maxLength={limit}>
          {defaultValue}
        </textarea>
      )
      break
    }
    default: {
      inputField = <input className={twMerge(inputClassnames)} name={inputName} type="text" maxLength={limit} />
      break
    }
  }

  return (
    <div>
      <label htmlFor={inputName}>{label}</label>
      {inputField}
    </div>
  )
}
