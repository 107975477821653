import * as React from "react"

import { range } from "~/src/lib/array"
import type { PageMeta } from "~/src/lib/page"

import "./PaginationBar.scss"

export const PaginationBar = ({
  totalPages,
  totalCount,
  currentPage,
  perPage,
  setPage,
}: PageMeta & { setPage: (n: number) => void; perPage: number }) => {
  const firstUserNumber = (currentPage - 1) * perPage + 1
  const lastUserNumber = Math.min(currentPage * perPage, totalCount)
  const handleIncrement = (e) => {
    e.preventDefault()
    if (currentPage < totalPages) {
      setPage(currentPage + 1)
    }
  }

  const handleDecrement = (e) => {
    e.preventDefault()
    if (currentPage > 1) {
      setPage(currentPage - 1)
    }
  }

  const handlePageChange = (e, n) => {
    e.preventDefault()
    setPage(n)
  }

  const pageNeighbours = 2

  if (!totalCount) return null
  const pages = fetchPageNumbers(totalPages, currentPage, pageNeighbours)

  return (
    <nav className="pagination-bar">
      {totalPages > 1 ? (
        <ul className="pagination-bar__link-list">
          {pages.map((page, index) => {
            const activeClass = currentPage === page ? "active" : ""
            if (page === LEFT_PAGE)
              return (
                <li key={index}>
                  <a href="#" onClick={handleDecrement}>
                    <span className="change-page" aria-hidden="true">
                      {"<"} Prev
                    </span>
                  </a>
                </li>
              )

            if (page === RIGHT_PAGE)
              return (
                <li key={index}>
                  <a href="#" onClick={handleIncrement}>
                    <span className="change-page" aria-hidden="true">
                      Next {">"}
                    </span>
                  </a>
                </li>
              )

            if (index === 0 && totalPages > 5 && currentPage > 4) {
              return (
                <li key={index}>
                  <a href="#" className={activeClass} onClick={(e) => handlePageChange(e, page)}>
                    {"<<"} First
                  </a>
                </li>
              )
            }

            if (index === pages.length - 1 && totalPages > 5) {
              return (
                <li key={index}>
                  <a href="#" className={activeClass} onClick={(e) => handlePageChange(e, page)}>
                    Last {">>"}
                  </a>
                </li>
              )
            }

            return (
              <li key={index}>
                <a href="#" className={activeClass} onClick={(e) => handlePageChange(e, page)}>
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      ) : null}
      <div className="pagination-bar__detail-display">
        Showing {firstUserNumber} - {lastUserNumber} of {totalCount}
      </div>
    </nav>
  )
}

const LEFT_PAGE = "LEFT"
const RIGHT_PAGE = "RIGHT"

const fetchPageNumbers = (totalPages: number, currentPage: number, pageNeighbours: number) => {
  /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = pageNeighbours + 2
  const totalBlocks = totalNumbers + 2

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - pageNeighbours)
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
    let pages: (string | number)[] = range(startPage, endPage)

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2
    const hasRightSpill = totalPages - endPage > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1)
        pages = [LEFT_PAGE, ...extraPages, ...pages]
        break
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset)
        pages = [...pages, ...extraPages, RIGHT_PAGE]
        break
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
        break
      }
    }

    return [1, ...pages, totalPages]
  }

  return range(1, totalPages)
}
