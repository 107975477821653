import React, { KeyboardEvent, ReactNode, useState } from "react"

import {
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  GiftIcon,
  PaperAirplaneIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline"

import { Bee } from "./BeeKit"
import { ReactionStatistics } from "./ReactionStatistics"
import { MessageQuote, ThankYouNoteMessageCarousel } from "./ThankYouNoteMessageCarousel"

type Source = [string, string, string] // [value, name, category]

enum SourceType {
  Campaign = "campaign",
  QuickSend = "quick_send",
  Storefront = "storefront",
}

type ThankYouNote = {
  id: number
  name: string
  senderName: string
  source: string
  sourceType: SourceType
  sourcePath: string
  viewGiftPath: string
  reaction?: Bee.ReactionType
  message?: string
  createdAt: string
}

type CountData = {
  all: number
  reactions: number
  messages: number
  heartEyes: number
  partyPopper: number
  smilingFace: number
  star: number
}

export type ThankYouNotesIndexProps = {
  url: string
  countData: CountData
  messageQuotes: MessageQuote[]
  sources: Source[]
  defaultParams: string
}

export type TableData = {
  data: ThankYouNote[]
  pagy: Bee.PageMeta
}

function partitionSources(sources: Source[]) {
  const campaigns = {}
  const quickSends = {}
  const stores = {}

  sources.forEach((source) => {
    const [value, name, category] = Object.values(source)
    switch (category) {
      case SourceType.Campaign:
        campaigns[value] = name
        break
      case SourceType.QuickSend:
        quickSends[value] = name
        break
      case SourceType.Storefront:
        stores[value] = name
        break
    }
  })

  return [campaigns, quickSends, stores]
}

function selectionsFromParams(
  campaigns: { [slug: string]: string },
  quickSends: { [slug: string]: string },
  params: URLSearchParams
): Bee.CheckBoxMultiCategorySelection[] {
  const campaignSlugs = Object.keys(campaigns)
  const quickSendSlugs = Object.keys(quickSends)
  const selections: Bee.CheckBoxMultiCategorySelection[] = []
  const pgSlugParams = params.getAll("q[preference_collector_slug_in][]")

  pgSlugParams.forEach((slug) => {
    if (campaignSlugs.includes(slug)) {
      selections.push({ value: slug, category: SourceType.Campaign })
    } else if (quickSendSlugs.includes(slug)) {
      selections.push({ value: slug, category: SourceType.QuickSend })
    }
  })

  return selections
}

export function ThankYouNotesIndex(props: ThankYouNotesIndexProps) {
  const { countData, messageQuotes, url, sources, defaultParams } = props
  const [campaigns, quickSends, stores] = partitionSources(sources)
  const initialParams = new URLSearchParams(defaultParams)
  const initialSelections = selectionsFromParams(campaigns, quickSends, initialParams)

  const [searchParams, setSearch] = useState<URLSearchParams>(initialParams)

  return (
    <div className="flex flex-col">
      <Bee.PageTitle
        className="border-b border-gray-200"
        title="Recipient Feedback"
        subtitle="See reactions and feedback from your recipients"
      />
      <div className="flex px-6 py-8 md:mb-3">
        <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
          <div className="flex flex-col gap-2 rounded-lg bg-white p-6 md:grow md:flex-row md:justify-evenly">
            <Statistic
              icon={<ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-blue-400" />}
              value={countData.all}
              text="responses"
            />
            <Statistic
              icon={<FaceSmileIcon className="h-6 w-6 text-blue-400" />}
              value={countData.reactions}
              text="reactions"
            />
            <Statistic
              icon={<EnvelopeIcon className="h-6 w-6 text-blue-400" />}
              value={countData.messages}
              text="messages"
            />
          </div>
          {messageQuotes.length > 0 ? (
            <div className="flex w-full flex-col gap-4 rounded-lg bg-white p-6 md:w-72">
              <h2 className="text-lg font-bold">Recent responses</h2>
              <ThankYouNoteMessageCarousel messageQuotes={messageQuotes} />
            </div>
          ) : null}

          <div className="w-full rounded-lg bg-white p-6 md:w-72">
            <ReactionStatistics {...countData} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 px-6 py-8">
        <SearchTools
          initialSelections={initialSelections}
          campaigns={campaigns}
          quickSends={quickSends}
          stores={stores}
          searchParams={searchParams}
          setSearch={setSearch}
        />
        <div className="flex flex-col gap-6">
          <Bee.AjaxDataTable
            className="hidden md:flex"
            url={url}
            searchParams={searchParams}
            columnDefs={[
              {
                name: "Recipient",
                field: "recipient_sort_name",
                renderCell: "name",
              },
              {
                name: "Sender",
                field: "sender_sort_name",
                renderCell: "senderName",
              },
              {
                name: "Source",
                field: "source_sort_name",
                renderCell: ({ source, sourceType, sourcePath }: ThankYouNote) => (
                  <SourceLink name={source} type={sourceType} url={sourcePath} />
                ),
              },
              {
                name: "Reaction",
                field: "reaction",
                renderCell: ({ reaction }: ThankYouNote) => <Bee.Reaction reaction={reaction} />,
              },
              {
                name: "Message",
                field: "message",
                className: "w-72",
                renderCell: ({ message }: ThankYouNote) => {
                  if (message && message.length > 100) {
                    return <Bee.ExpandableText className="w-72">{message}</Bee.ExpandableText>
                  } else {
                    return <p className="hyphens-auto whitespace-normal break-words">{message}</p>
                  }
                },
              },
              {
                name: "Received",
                field: "created_at",
                renderCell: (row: ThankYouNote) => {
                  return (
                    <div className="flex flex-col">
                      {row.createdAt}
                      <a
                        href={row.viewGiftPath}
                        target="_blank"
                        className="leading-tight text-gray-500 underline"
                        rel="noreferrer"
                      >
                        View gift
                      </a>
                    </div>
                  )
                },
              },
            ]}
            renderSupplemental={(thankYouNotes: ThankYouNote[]) => (
              <div className="flex flex-col gap-3 md:hidden">
                {thankYouNotes.map((thankYouNote: ThankYouNote) => {
                  return <MobileThankYou key={thankYouNote.id} thankYouNote={thankYouNote} />
                })}
              </div>
            )}
            keyFn={(thankYouNote: ThankYouNote) => thankYouNote.id}
          />
        </div>
      </div>
    </div>
  )
}

function SearchTools({
  campaigns,
  quickSends,
  stores,
  searchParams,
  initialSelections,
  setSearch,
}: {
  campaigns: { [slug: string]: string }
  quickSends: { [slug: string]: string }
  stores: { [id: string]: string }
  searchParams: URLSearchParams
  initialSelections: Bee.CheckBoxMultiCategorySelection[]
  setSearch: (searchParams: URLSearchParams) => void
}) {
  const [campaignFilterOpen, setCampaignFilterOpen] = useState(false)
  const config: Bee.CheckBoxMultiCategoryConfig = {
    [SourceType.Campaign]: {
      label: (
        <span className="inline-flex gap-2">
          <PaperAirplaneIcon className="h-4 w-4" />
          Campaigns
        </span>
      ),
      selectAllText: "All Campaigns",
      options: campaigns,
    },
    [SourceType.QuickSend]: {
      label: (
        <span className="inline-flex gap-2">
          <GiftIcon className="h-4 w-4" />
          Quick / Surprise Sends
        </span>
      ),
      selectAllText: "All Quick / Surprise Sends",
      options: quickSends,
    },
    [SourceType.Storefront]: {
      label: (
        <span className="inline-flex gap-2">
          <ShoppingCartIcon className="h-4 w-4" />
          Storefronts
        </span>
      ),
      selectAllText: "All Storefronts",
      options: stores,
    },
  }

  const setSearchText = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      const newSearch = new URLSearchParams(searchParams)
      newSearch.delete(
        "q[store_order_user_name_or_store_name_or_order_recipient_name_or_order_recipient_sender_name_or_preference_collector_name_cont]"
      )
      newSearch.append(
        "q[store_order_user_name_or_store_name_or_order_recipient_name_or_order_recipient_sender_name_or_preference_collector_name_cont]",
        e.currentTarget.value
      )
      setSearch(newSearch)
    }
  }

  return (
    <div className="flex w-full flex-col gap-4 md:flex-row">
      <Bee.SearchInput placeholder="Search" labelProps={{ className: "w-full" }} onKeyDown={setSearchText} />
      <Bee.Tip open={campaignFilterOpen} placement="bottom">
        <Bee.TipTrigger>
          <Bee.Button
            className="border-gray-300 bg-white text-gray-600"
            onClick={() => setCampaignFilterOpen(!campaignFilterOpen)}
            type="button"
          >
            Filter by source
          </Bee.Button>
        </Bee.TipTrigger>
        <Bee.TipContent>
          <div className="mt-2 flex w-[247px] flex-col gap-4 bg-white p-4 text-sm text-gray-900 shadow">
            <Bee.CheckBoxMultiCategorySelect
              defaultSelections={initialSelections}
              config={config}
              selectAllText="All Responses"
              onChange={(selectedOptions) => {
                const campaignOptions = selectedOptions.filter(({ category }) => category == SourceType.Campaign)
                const quickSendOptions = selectedOptions.filter(({ category }) => category == SourceType.QuickSend)
                const storeOptions = selectedOptions.filter(({ category }) => category == SourceType.Storefront)

                const newSearch = new URLSearchParams(searchParams)
                newSearch.delete("q[preference_collector_slug_in][]")
                newSearch.delete("q[g][0][g][0][preference_collector_slug_in][]")
                newSearch.delete("q[g][0][g][1][store_id_in][]")
                newSearch.delete("q[g][0][g][1][order_recipient_id_null]")
                newSearch.delete("q[g][0][m]")

                if (
                  selectedOptions.length !=
                  Object.keys(campaigns).length + Object.keys(quickSends).length + Object.keys(stores).length
                ) {
                  campaignOptions.forEach(({ value }) =>
                    newSearch.append("q[g][0][g][0][preference_collector_slug_in][]", value)
                  )
                  quickSendOptions.forEach(({ value }) =>
                    newSearch.append("q[g][0][g][0][preference_collector_slug_in][]", value)
                  )
                  if (storeOptions.length > 0) {
                    storeOptions.forEach(({ value }) => newSearch.append("q[g][0][g][1][store_id_in][]", value))
                    newSearch.append("q[g][0][g][1][order_recipient_id_null]", "true")
                    newSearch.append("q[g][0][m]", "or")
                  }
                }

                setSearch(newSearch)
              }}
            />
          </div>
        </Bee.TipContent>
      </Bee.Tip>
    </div>
  )
}

function MobileThankYou({ thankYouNote }: { thankYouNote: ThankYouNote }) {
  const { reaction, message, name, viewGiftPath, senderName, source, createdAt } = thankYouNote
  const [isExpanded, setExpanded] = useState(false)
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-4">
      <Bee.Reaction reaction={reaction} />
      <p className="w-[calc(100vw-5rem)] hyphens-auto whitespace-normal break-words text-gray-900">
        {message ? `"${message}"` : ""}
      </p>
      <p className="font-medium text-gray-900">{name}</p>
      <div className="mt-2 flex flex-row justify-between text-sm leading-tight text-gray-500 underline">
        <a href={viewGiftPath}>View gift</a>
        <button type="button" onClick={() => setExpanded(!isExpanded)}>
          See details
        </button>
      </div>
      <div
        className={`flex-col rounded-lg bg-gray-100 p-2 leading-tight text-gray-900 ${isExpanded ? "flex" : "hidden"}`}
      >
        <p>Sender: {senderName}</p>
        <p>Source: {source}</p>
        <p>Received: {createdAt}</p>
      </div>
    </div>
  )
}

function SourceLink({ name, type, url }: { name: string; type: SourceType; url: string }) {
  let Icon: typeof PaperAirplaneIcon | null = null

  let hoverContent = ""

  switch (type) {
    case SourceType.Campaign:
      Icon = PaperAirplaneIcon
      hoverContent = "Sent via Preferred Gift Campaign"
      break
    case SourceType.QuickSend:
      Icon = GiftIcon
      hoverContent = "Sent via Quick/Surprise Sends"
      break
    case SourceType.Storefront:
      Icon = ShoppingCartIcon
      hoverContent = "Sent via Storefront"
      break
  }

  return (
    <Bee.Tip key={name}>
      <Bee.TipTrigger>
        <a className="text-blue-600" href={url} target="_blank" rel="noreferrer">
          <span className="inline-flex items-center gap-2">
            {<Icon className={"h-4 w-4 flex-shrink-0"} />}
            {name}
          </span>
        </a>
      </Bee.TipTrigger>

      <Bee.TipBubble showArrow={false}>
        <span className="text-sm font-bold">{hoverContent}</span>
      </Bee.TipBubble>
    </Bee.Tip>
  )
}

function Statistic({ icon, value, text }: { icon: ReactNode; value: number; text: string }) {
  return (
    <div className="flex flex-row items-center gap-1 md:gap-4">
      {icon}
      <div className="flex flex-row gap-1 md:flex-col md:gap-0">
        <p className="mb-[-0.5rem] text-xl font-medium text-navy-800 md:text-3xl">{value}</p>
        <p className="flex flex-col justify-end text-gray-500">{text}</p>
      </div>
    </div>
  )
}
