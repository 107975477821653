import React from "react"

import { GiftIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline"

export function Description() {
  return (
    <div className="mt-4 grid grid-cols-[2rem_1fr] gap-x-2 gap-y-4 rounded-lg bg-gray-50 p-4">
      <Square3Stack3DIcon className="mt-1 h-6 w-6 text-gray-400" />
      <div className="flex flex-col">
        <h5 className="tex-sm font-medium">Add as option</h5>
        <p className="text-xs">
          Selecting items for your recipient to choose from. Recipient can choose one of all of the gift options you
          select.
        </p>
      </div>
      <GiftIcon className="mt-1 h-6 w-6 text-gray-400" />
      <div className="flex flex-col">
        <h5 className="tex-sm font-medium">Add as included gift</h5>
        <p className="text-xs">Items that will be automatically included in a recipient&apos;s shipment.</p>
      </div>
    </div>
  )
}
