export type Visibility = "all" | "favorites" | "hidden"

function elIsVisible(el: HTMLElement): boolean {
  return el.offsetWidth > 0 || el.offsetHeight > 0
}

function hideEl(el: HTMLElement): void {
  el.setAttribute("style", "display: none")
}

function showEl(el: HTMLElement): void {
  el.removeAttribute("style")
}

function elTagged(el: HTMLElement, filterTags: string[]): boolean {
  if (filterTags.length == 0) {
    return true
  }

  return filterTags.some((filterTag) => el.dataset[`displayTag-${filterTag}`] === "")
}

function anyElVisible(nodes: NodeList): boolean {
  for (const node of nodes) {
    if (node instanceof HTMLElement && elIsVisible(node)) {
      return true
    }
  }

  return false
}

export function toggleVisibility(visibility: Visibility, filterTags: string[]): boolean {
  const handleRows = () => {
    document.querySelectorAll(".project-products .group-row").forEach((rowEl) => {
      if (rowEl instanceof HTMLElement) {
        showEl(rowEl)

        if (anyElVisible(rowEl.querySelectorAll("li.searchable"))) {
          window.dispatchEvent(new CustomEvent("stickQuickNav"))
        } else {
          hideEl(rowEl)
          window.dispatchEvent(new CustomEvent("unstickQuickNav"))
        }
      }
    })
  }

  switch (visibility) {
    case "all":
      document.querySelectorAll("li.searchable[data-hidden='false']").forEach((el) => {
        if (el instanceof HTMLElement) {
          if (elTagged(el, filterTags)) {
            showEl(el)
          } else {
            hideEl(el)
          }
        }
      })
      document.querySelectorAll("li.searchable[data-hidden='true']").forEach((el) => {
        if (el instanceof HTMLElement) {
          hideEl(el)
        }
      })
      break

    case "favorites":
      document.querySelectorAll("li.searchable[data-love='false']").forEach((el) => {
        if (el instanceof HTMLElement) {
          hideEl(el)
        }
      })
      document.querySelectorAll("li.searchable[data-love='true']").forEach((el) => {
        if (el instanceof HTMLElement) {
          if (elTagged(el, filterTags)) {
            showEl(el)
          } else {
            hideEl(el)
          }
        }
      })
      break
    case "hidden":
      document.querySelectorAll("li.searchable[data-hidden='false']").forEach((el) => {
        if (el instanceof HTMLElement) {
          hideEl(el)
        }
      })

      document.querySelectorAll("li.searchable[data-hidden='true']").forEach((el) => {
        if (el instanceof HTMLElement) {
          if (elTagged(el, filterTags)) {
            showEl(el)
          } else {
            hideEl(el)
          }
        }
      })
      break
  }

  handleRows()
  return !anyElVisible(document.querySelectorAll(".project-products li.searchable"))
}

export function getEmptyMessageTarget() {
  return document.querySelector(".project-products")
}
