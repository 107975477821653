import React from "react"

import { Bee } from "~/src/components/BeeKit"
import { iClient } from "~/src/lib/appClients"

export type SolutionInstanceToggleProps = {
  solutionId: number
  solutionInstanceId: number
  initialChecked: boolean
}

export function SolutionInstanceToggle({
  solutionId,
  solutionInstanceId,
  initialChecked,
}: SolutionInstanceToggleProps) {
  const handleChange = (enabled: Boolean) => {
    ;(async () => {
      try {
        await iClient.post(`/tray_io/solutions/${solutionId}/instances/${solutionInstanceId}/toggle`, {
          enabled,
        })
      } catch (error) {
        if (error.name === "AxiosError") {
          console.error(error.response.data.message)
        } else {
          console.error(error)
        }
      }
    })()
  }

  return <Bee.Toggle defaultChecked={initialChecked} onChange={handleChange} />
}
