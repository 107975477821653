import React, { PropsWithChildren, useState } from "react"

import { twMerge } from "tailwind-merge"

export function ExpandableText(props: PropsWithChildren<{ className?: string }>) {
  const { children, className } = props
  const [isExpanded, setExpanded] = useState(false)

  return (
    <div>
      <p
        className={twMerge(`hyphens-auto whitespace-normal break-words ${isExpanded ? "" : "line-clamp-3"}`, className)}
      >
        {children}
      </p>
      <button className="text-gray-500 underline" onClick={() => setExpanded(!isExpanded)}>
        {isExpanded ? "Show less" : "Show more"}
      </button>
    </div>
  )
}
