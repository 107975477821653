import React, { useState } from "react"

import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import ahoy from "ahoy.js"
import { twMerge } from "tailwind-merge"

import { Orientation } from "../types/orientation"
import { Bee } from "./BeeKit"
import { DisplayTagList } from "~/src/components/DisplayTagList"
import { PriceQuantity, ProductPricingTable } from "~/src/components/ProductPricingTable"
import { RailsForm } from "~/src/components/RailsForm"
import { Select } from "~/src/components/Select"
import { useRequestCart } from "~/src/hooks/useRequestCart"
import { appClient } from "~/src/lib/appClients"
import { Format } from "~/src/lib/format"
import { iname } from "~/src/lib/iname"
import { type DisplayTag } from "~/src/types/displayTag"

//region Types
type Product = {
  id: string
  brand: string
  name: string
  description: string
  hasStockColors: boolean
  onDemand: boolean
  shippingCost?: number
  lowQuantity: number
  available: boolean
}

type PriceGroup = {
  id: string
  name?: string
  priceQuantities: PriceQuantity[]
}

type VariantPhoto = {
  id: string
  variantId: string
  imageUrl: string
  thumbUrl: string
  label?: string
  colorName?: string
  colorCode?: string
}

type Flavor = {
  name: string
  id: string
}

type QuantityPickerProps = {
  minimumQuantity?: number
}

type PriceDetailsProps = {
  product: Product
  priceGroup?: PriceGroup
}

type ProductDetailFormProps = {
  product: Product
  variantPhoto: VariantPhoto
  flavors: Flavor[]
  priceGroup?: PriceGroup
  action: string
  opens: string
  onSubmit?: React.FormEventHandler
}

type FlavorPickerProps = {
  flavors: Flavor[]
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
}

export type ProductDetailProps = {
  className?: string
  product: Product
  variantPhotos: VariantPhoto[]
  flavors: Flavor[]
  priceGroup?: PriceGroup
  displayTags: DisplayTag[]
  hidePrice: boolean
  backUrl?: string
  action: string
  paramOpens: string
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}
//endregion

//region Component
export function ProductDetail(props: ProductDetailProps) {
  const {
    className,
    product,
    variantPhotos,
    flavors,
    priceGroup,
    displayTags,
    hidePrice,
    backUrl,
    action,
    paramOpens,
    onSubmit,
  } = props

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div className={twMerge("flex flex-col gap-2 p-6", className)}>
      {backUrl ? (
        <a
          href={backUrl}
          className="mr-auto flex flex-initial cursor-pointer items-center gap-2 text-lg text-gray-500 hover:underline"
        >
          <ArrowLeftIcon className="h-4 w-4" /> Back
        </a>
      ) : null}

      <h1 className="text-3xl font-medium md:hidden">
        {product.brand} {product.name}
      </h1>

      {!product.available ? (
        <Bee.Pill className="bg-pastelpink-100 text-red-600 md:hidden">Currently unavailable</Bee.Pill>
      ) : null}

      <div className="flex w-full flex-col gap-4 md:flex-row md:justify-center md:gap-8">
        <Bee.ImageCarousel
          className="max-h-[700px] basis-1 sm:min-w-0 sm:max-w-[640px] sm:grow sm:basis-1/2 xl:basis-1/3"
          thumbnailContainerClassName="hidden md:flex flex-wrap gap-4 py-2"
          thumbnailClassName="w-12 h-12"
          selectedThumbnailClassName="overflow-hidden"
          selectedIndex={selectedIndex}
          onSelectIndex={setSelectedIndex}
        >
          <Bee.ImageCarousel.Images>
            {variantPhotos.map((photo, index) => (
              <img key={index} src={photo.imageUrl} className="h-[300px] object-contain sm:h-[500px]" />
            ))}
          </Bee.ImageCarousel.Images>
          {variantPhotos.length > 1 ? (
            <Bee.ImageCarousel.Thumbnails>
              {variantPhotos.map((photo, index) => (
                <img key={index} src={photo.thumbUrl} />
              ))}
            </Bee.ImageCarousel.Thumbnails>
          ) : null}
        </Bee.ImageCarousel>

        <div className="flex basis-1/2 flex-col gap-6 xl:basis-1/3">
          {!product.available ? (
            <Bee.Pill className="hidden bg-pastelpink-100 text-red-600 md:block">Currently unavailable</Bee.Pill>
          ) : null}
          <h1 className="hidden text-4xl font-medium md:block">
            {product.brand} {product.name}
          </h1>
          <p>{product.description}</p>
          {priceGroup?.name ? <p>{priceGroup.name}</p> : null}
          {displayTags.length > 0 ? <DisplayTagList displayTags={displayTags} className="mb-3" /> : null}

          {hidePrice ? (
            <em>Contact us for pricing</em>
          ) : (
            <>
              <PriceDetails product={product} priceGroup={priceGroup} />
              {variantPhotos.length > 1 && product.hasStockColors ? (
                <Bee.ColorSwatch
                  className="order-first md:order-none"
                  colors={variantPhotos.map((vp) => ({
                    colorName: vp.colorName || "",
                    colorCode: vp.colorCode,
                    fallbackImageUrl: vp.thumbUrl,
                  }))}
                  onClick={(_color, newIndex) => {
                    setSelectedIndex(newIndex || 0)
                  }}
                />
              ) : null}

              {product.available ? (
                <ProductDetailForm
                  product={product}
                  priceGroup={priceGroup}
                  flavors={flavors}
                  variantPhoto={variantPhotos[selectedIndex]}
                  action={action}
                  opens={paramOpens}
                  onSubmit={onSubmit}
                />
              ) : null}
            </>
          )}

          {displayTags.length > 0 ? (
            <div className="text-xs">
              <a href="https://www.brilliantmade.com/company/sustainability" className="cursor-pointer underline">
                Learn more
              </a>{" "}
              about Brilliant’s sustainability initiatives.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
//endregion

//region Private
function ProductDetailForm(props: ProductDetailFormProps) {
  const { product, priceGroup, variantPhoto, flavors, action, onSubmit, opens = "" } = props
  const { itemChanged } = useRequestCart()
  const [selectedFlavorId, setSelectedFlavorId] = useState<string | null>(null)
  const [lessThanMinQuantitySubmitted, setLessThanMinQuantitySubmitted] = useState(false)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    onSubmit?.(event)

    const { currentTarget } = event

    if (!(currentTarget instanceof HTMLFormElement)) {
      throw new TypeError(`Expected ${currentTarget} to be a HTMLFormElement`)
    }

    const quantity = currentTarget[iname().orderItem.quantity._].value

    if (product.lowQuantity && quantity < product.lowQuantity) {
      setLessThanMinQuantitySubmitted(true)
    }

    const formData = new FormData(currentTarget)
    ahoy.track("add_from_product", {
      resource_type: "Product",
      resource_id: product.id,
      variant_id: formData.get("order_item[variant_id]"),
    })
    await appClient.post(action, formData, { headers: { Accept: "application/json" } })

    itemChanged()
  }

  const baseName = iname().orderItem

  return (
    <RailsForm className="flex flex-col gap-6" method="post" action={action} onSubmit={handleSubmit}>
      {priceGroup ? <Bee.HiddenInput name={iname().orderItem.priceGroupId} value={priceGroup.id} /> : null}
      <Bee.HiddenInput name={baseName.productId} value={product.id} />
      <Bee.HiddenInput name={baseName.opens} {...(opens ? { value: opens } : {})} />
      <Bee.HiddenInput name={baseName.variantPhotoId} value={variantPhoto.id} />
      <Bee.HiddenInput name={baseName.variantId} value={selectedFlavorId ? selectedFlavorId : variantPhoto.variantId} />

      {flavors.length > 0 ? (
        <FlavorPicker flavors={flavors} onChange={(e) => setSelectedFlavorId(e.target.value)} />
      ) : null}
      {!product.onDemand ? <QuantityPicker minimumQuantity={product.lowQuantity} /> : null}
      {lessThanMinQuantitySubmitted ? (
        <p>
          This item was added to your project cart but requires a minimum order of {product.lowQuantity} units. Please
          let us know in the estimate request if you need less than the minimum requirement.
        </p>
      ) : null}
      <Bee.Tip placement="bottom">
        <Bee.TipTrigger className="w-full">
          <Bee.Button
            type="submit"
            disabled={product.onDemand}
            className={twMerge("w-full", product.onDemand && "cursor-not-allowed")}
          >
            Add to project request
          </Bee.Button>
        </Bee.TipTrigger>

        {product.onDemand && (
          <Bee.TipBubble className="mx-4 max-w-64 rounded-none bg-black text-sm text-white">
            On-demand product cannot be added to project request
          </Bee.TipBubble>
        )}
      </Bee.Tip>
    </RailsForm>
  )
}

function QuantityPicker(props: QuantityPickerProps) {
  const { minimumQuantity } = props
  return (
    <div className="flex flex-col">
      <label className="font-medium text-black">Quantity</label>
      <Bee.Input
        name={iname().orderItem.quantity}
        className="w-full sm:w-[250px]"
        type="number"
        min="1"
        placeholder={minimumQuantity ? `Minimum ${minimumQuantity}` : ""}
      />
    </div>
  )
}

function FlavorPicker(props: FlavorPickerProps) {
  const { flavors, onChange } = props
  return (
    <div className="flex flex-col gap-2">
      <label className="font-medium text-black">Flavor</label>
      <Select
        className="w-full sm:w-[250px]"
        name={iname().orderItem.flavor._}
        options={[
          { value: "", label: "" },
          ...flavors.map((flavor) => {
            return { value: flavor.id, label: flavor.name }
          }),
        ]}
        onChange={onChange}
      />
    </div>
  )
}

function PriceDetails(props: PriceDetailsProps) {
  const { product, priceGroup } = props
  if (priceGroup == null) {
    return null
  }

  const [firstPriceQuantity] = priceGroup.priceQuantities.slice(0)
  const [lastPriceQuantity] = priceGroup.priceQuantities.slice(-1)
  const lowPrice = lastPriceQuantity?.price
  const lowQuantity = firstPriceQuantity?.quantity

  const renderOnDemand = () => {
    return (
      <div>
        <div>{lowPrice != null ? Format.usd(lowPrice) : "Not Available"}</div>
        {product.shippingCost != null ? <div>(plus {Format.usd(product.shippingCost)} shipping)</div> : null}
      </div>
    )
  }

  const renderLowQuantity = () => {
    return (
      <span>
        From {Format.usd(lowPrice)} (min {lowQuantity})
      </span>
    )
  }

  const renderNoQuantity = () => {
    return lowPrice != null ? <span>From {Format.usd(lowPrice)}</span> : null
  }

  if (product.onDemand) {
    return renderOnDemand()
  }

  return priceGroup.priceQuantities.length > 1 ? (
    <ProductPricingTable
      priceQuantities={priceGroup.priceQuantities}
      orientation={priceGroup.priceQuantities.length > 4 ? Orientation.Portrait : Orientation.Landscape}
      className="flex-none"
    />
  ) : lowPrice ? (
    lowQuantity && lowQuantity != 1 ? (
      renderLowQuantity()
    ) : (
      renderNoQuantity()
    )
  ) : null
}
//endregion
