import React, { useEffect } from "react"

import { humanize } from "underscore.string"

import { RailsForm } from "./RailsForm"
import { StorefrontDropdownOption } from "./StorefrontDropdown"
import { StorefrontDropdownMenu } from "./StorefrontDropdownMenu"
import style from "./StorefrontOrderHistoryControls.module.scss"
import { Input, StorefrontDateRangePicker, StorefrontDropdown } from "~/src/components"

export type StorefrontOrderHistoryControlsProps = {
  search: string
  status: string
  beginAt: string
  endAt: string
  storeHistoryPath: string
  storeHistoryCsvPath: string
}

enum ReportType {
  Detail = "detail",
  Summary = "summary",
  Payment = "payment",
}

export function StorefrontOrderHistoryControls(props: StorefrontOrderHistoryControlsProps) {
  const { status, beginAt, endAt, storeHistoryPath, storeHistoryCsvPath } = props

  // Control when the history table shows to prevent ugly jank
  useEffect(() => {
    const tableContainer = document.getElementById("history-table")
    if (tableContainer instanceof HTMLElement) tableContainer.removeAttribute("hidden")
  }, [])

  const statusOptions: StorefrontDropdownOption[] = [
    ...["pending_approval", "shipped", "canceled", "in_fulfillment"].map((x) => ({
      label: humanize(x),
      value: x,
    })),
    { label: "Clear Status" },
  ]

  const getCsvPath = (type: ReportType) => {
    return [storeHistoryCsvPath, `report_type=${type}`].join(storeHistoryCsvPath.includes("?") ? "&" : "?")
  }

  return (
    <div className={style.base}>
      <h1 className="font-souvenir">Order History</h1>

      <div className={style.controlsRow}>
        <RailsForm method="get" action={storeHistoryPath}>
          <div className={style.filterRow}>
            <Input
              type="text"
              name="search"
              className={style.input}
              placeholder="Search by Name, Email or Order Number"
            ></Input>

            <StorefrontDropdown
              name="status"
              options={statusOptions}
              placeholder="Filter by Status"
              defaultValue={status}
            />

            <StorefrontDateRangePicker
              defaultValue={{ from: beginAt, to: endAt }}
              fromName="begin_at"
              placeholder="Filter by Time"
              toName="end_at"
            />

            <input className={style.button} type="submit" value="Apply" />

            <a className={style.button} href={storeHistoryPath}>
              Clear
            </a>
          </div>
        </RailsForm>

        <StorefrontDropdownMenu className={style.downloadCSVMenu} title="Download CSV...">
          <a href={getCsvPath(ReportType.Summary)}>
            <strong>Order summary</strong>
            <br />
            One order per row
          </a>
          <a href={getCsvPath(ReportType.Detail)}>
            <strong>Order details</strong>
            <br />
            One order line item per row
          </a>
          <a href={getCsvPath(ReportType.Payment)}>
            <strong>Order payments</strong>
            <br />
            One payment method per row
          </a>
        </StorefrontDropdownMenu>
      </div>
    </div>
  )
}
