import React from "react"

import { Bee } from "./BeeKit"
import { ImageWithFallback } from "~/src/components/ImageWithFallback"
import { isPresent } from "~/src/lib/any"

export type ThankYouNoteSummaryProps = {
  senderName: string
  giftInformation: { url: string; name: string }[]
  viaRecipient: boolean
  notecardMessage: string
}

export function ThankYouNoteSummary(props: ThankYouNoteSummaryProps) {
  const { viaRecipient, senderName, giftInformation, notecardMessage } = props

  return (
    <div>
      <h1 className="text-6xl font-medium text-navy-800">Send a thank you note</h1>
      <div className="mt-7 md:mt-12">
        <Bee.Pill className="bg-blueslate-200 text-sm font-medium text-navy-800">
          <span>{viaRecipient ? `From ${senderName}` : "Your order"} </span>
        </Bee.Pill>

        <div className="flex flex-col gap-5 rounded-md bg-white p-6">
          {isPresent(notecardMessage) ? (
            <div className="whitespace-pre-line rounded-sm p-4 shadow-md">{notecardMessage}</div>
          ) : null}
          {giftInformation.map((giftInformation, index) => {
            return (
              <div key={index} className="flex items-center gap-2">
                <ImageWithFallback src={giftInformation.url} className="w-12" />
                <span className="text-bold text-sm">{giftInformation.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
