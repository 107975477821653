import React, { useMemo, useRef, useState } from "react"

import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { useLoaderData, useSearchParams } from "react-router-dom"

import { Bee } from "../BeeKit"
import { BudgetDisplayPill } from "../BudgetDisplayPill"
import { EmptyResults } from "../EmptyResults"
import { ProductSelection } from "../ProductSelection"
import { VariantDetailModal } from "../ProductSelection/VariantDetailModal"
import { WorkflowHeading } from "../WorkflowHeading"
import { QuickSendEditBaseErrorsFlash } from "./QuickSendEditBaseErrorsFlash"
import { QuickSendEditSelectionInputs } from "./QuickSendEditSelectionInputs"
import { QuickSendProductSelectionSummary } from "./QuickSendProductSelectionSummary"
import { PagyNav } from "~/src/components"
import { WorkflowContainer } from "~/src/components/WorkflowContainer"
import { summarizeSelections } from "~/src/lib/quickSend"
import { Pagy } from "~/src/serializedRecords"
import { BudgetAmount } from "~/src/types/budget"
import { Orientation } from "~/src/types/orientation"
import { QuickSend, QuickSendKind, QuickSendSelectionKind } from "~/src/types/preferredGift"
import {
  SelectableType,
  type ProductSelectionItem,
  type QuickSendSelectionItem,
  type Selectable,
  type StoreProduct,
  type StoreProductGroup,
  type Variant,
} from "~/src/types/productSelection"

export type QuickSendEditSelectionsContentProps = {
  meta: Pagy
  quickSend: QuickSend
  formUrl: string
  backUrl: string
  selections: ProductSelectionItem[]
  availableStoreBudget: BudgetAmount
  customBudgetUnit?: string
  items: Selectable[]
  storeProductGroups: StoreProductGroup[]
}

export function QuickSendEditSelectionsContent() {
  const data = useLoaderData() as QuickSendEditSelectionsContentProps

  const {
    meta: { pages, next, previous, series = [] },
    quickSend,
    formUrl,
    backUrl,
    availableStoreBudget,
    customBudgetUnit,
    items,
    storeProductGroups,
  } = data

  const quickSendKind = quickSend.kind
  const selections = quickSend.selections

  const [searchParams, setSearchParams] = useSearchParams()

  const scrollRoot = useRef<HTMLDivElement>(null)
  const storeProductGroupTabs = useMemo(
    () =>
      [{ name: "All Products" }, ...storeProductGroups].map(({ id, name }: StoreProductGroup) => ({
        label: name,
        id: id,
      })),
    [storeProductGroups]
  )

  const handleCategoryTabClick = (category?: string) => {
    setSearchParams((oldSearchParams) => {
      const newSearchParams = new URLSearchParams(oldSearchParams)

      category ? newSearchParams.set("category", category) : newSearchParams.delete("category")
      newSearchParams.delete("page")

      return newSearchParams
    })
  }

  return (
    <ProductSelection.FormPage
      formUrl={formUrl}
      selections={selections}
      hiddenInputs={<QuickSendEditSelectionInputs />}
    >
      <div className="flex h-[calc(100vh-3.5rem)] flex-col gap-8 pb-32 md:pb-6">
        <WorkflowContainer className="flex grow flex-col gap-8 overflow-y-scroll" ref={scrollRoot}>
          <Heading budget={availableStoreBudget} currencyUnit={customBudgetUnit} quickSendKind={quickSendKind} />
          <div className="flex md:hidden">
            <ProductSelection.Description />
          </div>

          <QuickSendEditBaseErrorsFlash quickSend={quickSend} className="m-0" />

          <OverBudgetFlash availableStoreBudget={availableStoreBudget} />

          <div className="mt-4 flex flex-col md:flex-row">
            <div className="flex w-full flex-col gap-8 md:pr-6">
              <Bee.Searchbar
                name="search"
                defaultValue={searchParams.get("search") ?? ""}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setSearchParams({ search: event.currentTarget.value })
                  }
                }}
                onClear={() => setSearchParams({})}
              />
              <Bee.TabBar className="mt-2">
                {storeProductGroupTabs.map(({ id: id_, label }: { label: string; id: number }) => {
                  const id = id_?.toString()
                  const isAllProductsSelected = !searchParams.get("category") && id == null
                  const isSelected = searchParams.get("category") === id || isAllProductsSelected
                  return (
                    <Bee.Tab key={id} selected={isSelected} onClick={() => handleCategoryTabClick(id)}>
                      {label}
                    </Bee.Tab>
                  )
                })}
              </Bee.TabBar>

              {items.length === 0 ? (
                <EmptyResults className="shrink-1 w-full" />
              ) : (
                <TileList selectables={items} quickSendKind={quickSendKind} />
              )}
              {series.length > 0 && pages > 1 && (
                <div className="mb-10 flex justify-center p-8 sm:mb-0">
                  <PagyNav previous={previous} next={next} series={series} />
                </div>
              )}
            </div>
            <div className="hidden md:block">
              <QuickSendProductSelectionSummary
                quickSendKind={quickSendKind}
                productFilter={(item: QuickSendSelectionItem) =>
                  !!item?.kind && item.kind != QuickSendSelectionKind.None
                }
              />
            </div>
          </div>
        </WorkflowContainer>

        <div className="fixed bottom-0 w-full bg-gray-50 md:relative">
          <div className="flex w-full flex-col">
            <div className="w-full bg-white md:hidden">
              <QuickSendProductSelectionSummary
                quickSendKind={quickSendKind}
                productFilter={(item: QuickSendSelectionItem) =>
                  !!item?.kind && item.kind != QuickSendSelectionKind.None
                }
              />
            </div>
            <QuickSendSelectionSubmissionControls backUrl={backUrl} availableStoreBudget={availableStoreBudget} />
          </div>
        </div>
      </div>
    </ProductSelection.FormPage>
  )
}

type TileListProps = {
  selectables: Selectable[]
  quickSendKind: QuickSendKind
}

function TileList(props: TileListProps) {
  const { selectables, quickSendKind } = props
  const selectedItems = ProductSelection.useSelectedItems<QuickSendSelectionItem>()

  return (
    <div className="flex flex-wrap gap-4 md:min-w-[664px]">
      {selectables.map((selectable: Selectable) => {
        const item = selectedItems[selectable.id]
        const itemSelected = !!item?.kind && item.kind != QuickSendSelectionKind.None
        return (
          <ProductSelection.CollectionItemTile
            key={selectable.id}
            selectable={selectable}
            selected={itemSelected}
            orientation={Orientation.Portrait}
          >
            <QuickSendProductSelectionControls item={selectable} quickSendKind={quickSendKind} />
          </ProductSelection.CollectionItemTile>
        )
      })}
    </div>
  )
}

function Heading({
  budget,
  currencyUnit,
  quickSendKind,
}: {
  budget: BudgetAmount
  currencyUnit?: string
  quickSendKind: QuickSendKind
}) {
  return (
    <WorkflowHeading title="Select products" subtitle={subheadingCopy(quickSendKind)}>
      <BudgetDisplayPill preamble="Available store budget: " budget={budget} currencyUnit={currencyUnit} />
    </WorkflowHeading>
  )
}

function subheadingCopy(quickSendKind: QuickSendKind) {
  if (quickSendKind === QuickSendKind.Surprise) {
    return "Select one or more items to send directly to a recipient"
  } else {
    return "Select one or more items to send directly to a recipient, or create a selection of items for them to choose from."
  }
}

function useIsEstimatedTotalOverStoreBudget(availableStoreBudget: BudgetAmount) {
  const selectedItems = Object.values(ProductSelection.useSelectedItems<QuickSendSelectionItem>())

  if (availableStoreBudget === null) {
    return false
  } else {
    const { estimatedTotal } = summarizeSelections(selectedItems)
    return estimatedTotal > availableStoreBudget
  }
}

function OverBudgetFlash(props: { availableStoreBudget: BudgetAmount }) {
  const { availableStoreBudget } = props

  return useIsEstimatedTotalOverStoreBudget(availableStoreBudget) ? (
    <Bee.Flash kind="error" className="mt-4">
      <p>
        You don&apos;t have enough budget available for this send. Please remove items or reach out to your Brilliant
        administrator about your budget.
      </p>
    </Bee.Flash>
  ) : null
}

function IncludedGiftButtonContent({ isGifted, quickSendKind }: { isGifted: boolean; quickSendKind: QuickSendKind }) {
  const unselectedText = quickSendKind === QuickSendKind.Standard ? "Add as included gift" : "Select gift"
  const selectedText = quickSendKind === QuickSendKind.Standard ? "Included gift" : "Selected"

  return buttonText(isGifted, selectedText, unselectedText)
}

function QuickSendSelectionSubmissionControls({
  backUrl,
  availableStoreBudget,
}: {
  backUrl: string
  availableStoreBudget: BudgetAmount
}) {
  const selections = ProductSelection.useSelectedItems<QuickSendSelectionItem>()
  const noSelections =
    Object.values(selections).filter((item: QuickSendSelectionItem) => item.kind != QuickSendSelectionKind.None)
      .length == 0

  return (
    <div className="flex w-full justify-center gap-4 bg-gray-50 px-6 py-4 md:py-0">
      <Bee.ButtonLink className="flex items-center gap-1" kind="secondary" href={backUrl}>
        <ArrowLeftIcon className="h-4 w-4" />
        <span>Back</span>
      </Bee.ButtonLink>

      <Bee.Button
        type="submit"
        className="flex items-center gap-1"
        disabled={noSelections || useIsEstimatedTotalOverStoreBudget(availableStoreBudget)}
      >
        <span>Continue</span>
        <ArrowRightIcon className="h-4 w-4" />
      </Bee.Button>
    </div>
  )
}

const buttonText = (selected: boolean, selectedText: string, unSelectedText: string) => {
  if (selected) {
    return (
      <div className="flex flex-row items-center justify-center gap-1">
        <CheckCircleIcon className="h-4 w-4" />
        {selectedText}
      </div>
    )
  } else {
    return unSelectedText
  }
}

type SelectionControlProps = {
  item: Selectable
  quickSendKind: QuickSendKind
}

function QuickSendProductSelectionControls(props: SelectionControlProps) {
  const { item, quickSendKind } = props
  const selectedItems = ProductSelection.useSelectedItems<QuickSendSelectionItem>()
  const setSelected = ProductSelection.useSetSelectedItem()
  const selectedItem = selectedItems[item.id]

  const selectionType = selectedItem?.kind || QuickSendSelectionKind.None
  const chosen = selectionType == QuickSendSelectionKind.Chosen
  const gifted = selectionType == QuickSendSelectionKind.Gifted

  const isDisabled = (selectable: Selectable): boolean => {
    if (selectable.type == SelectableType.StoreProduct) {
      const { inventoryCount, variantCount, inventoryRequired } = selectable as StoreProduct

      if ((inventoryCount <= 0 || variantCount == 0) && inventoryRequired) {
        return true
      }
    } // else if (selectable.type == SelectableType.StoreProduct) required for StoreKit support

    return false
  }

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const onChoice = (kind: QuickSendSelectionKind, selectedVariant?: Variant) => {
    const selection: ProductSelectionItem = {
      id: selectedItem?.id,
      type: "QuickSend",
      selectableId: item.id,
      selectableType: item.type,
      selectable: item,
      kind,
      selectedVariant,
    }

    setSelected(selection)
  }

  const onGiftClick = (kind: QuickSendSelectionKind, selectedVariant?: Variant) => {
    if (!gifted && item.type == SelectableType.StoreProduct) {
      const { variantCount } = item as StoreProduct

      if (variantCount > 1) {
        setModalIsOpen(true)
        return
      }
    }

    onChoice(kind, selectedVariant)
  }

  const StandardQuickSendButtons = () => (
    <Bee.ButtonGroup>
      <Bee.Button
        onClick={() => onChoice(chosen ? QuickSendSelectionKind.None : QuickSendSelectionKind.Chosen)}
        type="button"
        kind="secondary"
        className={chosen ? "bg-navy-800 text-white" : "border-gray-300"}
        disabled={isDisabled(item)}
      >
        {buttonText(chosen, "Added as option", "Add as option")}
      </Bee.Button>
      <Bee.Button
        onClick={() => onGiftClick(gifted ? QuickSendSelectionKind.None : QuickSendSelectionKind.Gifted)}
        type="button"
        kind="secondary"
        className={gifted ? "bg-navy-800 text-white" : "border-gray-300"}
        disabled={isDisabled(item)}
      >
        <IncludedGiftButtonContent isGifted={gifted} quickSendKind={quickSendKind} />
      </Bee.Button>
    </Bee.ButtonGroup>
  )

  const SurpriseSendButtons = () => (
    <Bee.Button
      onClick={() => onGiftClick(gifted ? QuickSendSelectionKind.None : QuickSendSelectionKind.Gifted)}
      type="button"
      kind="secondary"
      className={gifted ? "bg-navy-800 text-white" : "border-gray-300"}
      disabled={isDisabled(item)}
    >
      <IncludedGiftButtonContent isGifted={gifted} quickSendKind={quickSendKind} />
    </Bee.Button>
  )

  return (
    <>
      {quickSendKind === QuickSendKind.Standard ? <StandardQuickSendButtons /> : <SurpriseSendButtons />}
      <VariantDetailModal
        selectable={item}
        selectedVariant={selectedItem?.selectedVariant}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        onChoice={onChoice}
      />
    </>
  )
}
