import * as React from "react"
import { createRef, useEffect, useMemo, useState } from "react"

import { twMerge } from "tailwind-merge"

import { STORE_PRODUCT_GROUP_DEFAULT_LABEL } from "~/constants"
import { Bee } from "~/src/components/BeeKit"

export type Tab = {
  label: string
  ref: React.RefObject<HTMLDivElement>
  onClick?: () => void
}

export type CategoryTabBarProps = {
  tabs: Tab[]
  scrollRoot: React.RefObject<HTMLDivElement>
  scrollOffset?: number
  className?: string
  containerClassName?: string
  kind?: "primary" | "secondary"
  initialIndex?: number
}

export function CategoryTabBar({
  tabs,
  scrollRoot,
  className,
  containerClassName,
  kind,
  initialIndex,
  scrollOffset = 0,
}: CategoryTabBarProps) {
  const filteredTabsWithIndex = tabs
    .map((tab, index) => {
      return { tab, index }
    })
    .filter(({ tab }) => tab.label !== STORE_PRODUCT_GROUP_DEFAULT_LABEL)
  const filteredTabs = filteredTabsWithIndex.map(({ tab }) => tab)
  const filteredInitialIndex = filteredTabsWithIndex.findIndex(({ index }) => index == initialIndex)
  const tabHeaders = useMemo(() => filteredTabs.map(() => createRef<HTMLButtonElement>()), [tabs])
  const [selectedIndex, setSelectedIndex] = useState(0)

  const calcSelectedIndex = () => {
    if (filteredTabs.length < 1) {
      return
    }

    const scrollRootTop = scrollRoot.current ? scrollRoot.current.getBoundingClientRect().top : 0
    const scrollTop = scrollRootTop + scrollOffset
    const firstVisibleIndex = filteredTabs.findIndex((tab) => {
      if (!tab.ref.current) {
        return false
      }

      const tabBottom = tab.ref.current.getBoundingClientRect().bottom
      return tabBottom >= scrollTop
    })

    if (firstVisibleIndex === -1) {
      setSelectedIndex(filteredTabs.length - 1)
    } else {
      setSelectedIndex(firstVisibleIndex)
    }
  }

  // Scroll header into view on index change
  useEffect(() => {
    const tabHeader = tabHeaders[selectedIndex]?.current
    if (tabHeader) {
      tabHeader.scrollIntoView({ block: "nearest" })
    }
  }, [selectedIndex])

  // Observe scrolling changes
  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: scrollRoot.current,
      rootMargin: `-${scrollOffset}px 0px 0px 0px`,
    }

    const observer = new IntersectionObserver(calcSelectedIndex, options)
    filteredTabs.forEach(({ ref }) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      filteredTabs.forEach(({ ref }) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [filteredTabs, scrollRoot, scrollOffset])

  const scrollToContent = (tab: Tab) => {
    if (tab.ref.current) {
      const root = scrollRoot.current || document.documentElement
      const scrollOffsetTop = root.offsetTop + scrollOffset
      const tabOffsetTop = tab.ref.current.offsetTop
      root.scrollTo({
        top: tabOffsetTop - scrollOffsetTop,
      })
      calcSelectedIndex()
    }
  }

  // Initial index scroll
  useEffect(() => {
    if (filteredInitialIndex >= 0) {
      const tab = filteredTabs[filteredInitialIndex]
      if (tab) {
        scrollToContent(tab)
      }
    }
  }, [initialIndex])

  return (
    <div className={twMerge("sticky", containerClassName)}>
      <Bee.TabBar kind={kind} className={className}>
        {filteredTabs.map((tab, index) => (
          <Bee.Tab
            ref={tabHeaders[index]}
            kind={kind}
            selected={index === selectedIndex}
            onClick={() => {
              scrollToContent(tab)
              if (tab.ref.current) {
                tab.onClick?.()
              }
            }}
            key={index}
          >
            {tab.label}
          </Bee.Tab>
        ))}
      </Bee.TabBar>
    </div>
  )
}
