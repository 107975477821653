import React, { useEffect } from "react"

import { useRouteError } from "react-router-dom"

import { Bee } from "./BeeKit"

/**
 * ErrorPage component is used to display a user-friendly error message when something goes wrong.
 * This component is designed to be used with React Router's error handling.
 *
 * @component
 * @example
 * ```tsx
 * <ErrorPage />
 * ```
 *
 * @remarks
 * This component utilizes the `useRouteError` hook from `react-router-dom` to capture and display route errors.
 * It also includes a button link to email support for further assistance.
 *
 * @param props - The props for the ErrorPage component.
 *
 * @returns A React component that renders an error message and a support link.
 */
export function ErrorPage() {
  const error = useRouteError()

  useEffect(() => {
    if (import.meta.env.PROD) {
      // @ts-ignore
      window?.Rollbar?.error(error)
    } else {
      console.error(error)
    }
  }, [error])

  return (
    <div className="flex flex-col items-center gap-2 p-10">
      <h1 className="text-xl font-medium">Oops! It looks like something went wrong.</h1>
      <p className="mb-4">These things happen from time to time. Our team has been notified of the issue.</p>
      <Bee.ButtonLink className="w-fit" href="mailto:support@brilliantmade.com" target="_blank">
        Email Support
      </Bee.ButtonLink>
    </div>
  )
}
