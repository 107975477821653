import React from "react"

import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"

export type ReactionStatisticsProps = {
  heartEyes: number
  partyPopper: number
  smilingFace: number
  star: number
  className?: string
  reactionClassName?: string
}

export function ReactionStatistics(props: ReactionStatisticsProps) {
  const { heartEyes, partyPopper, smilingFace, star, className, reactionClassName } = props
  return (
    <div className={twMerge("grid h-full w-full grid-cols-2 gap-x-12 gap-y-4", className)}>
      <ReactionStat
        reaction={Bee.ReactionType.HEART_EYES}
        count={heartEyes}
        className={twMerge("justify-end", reactionClassName)}
      />
      <ReactionStat reaction={Bee.ReactionType.PARTY_POPPER} count={partyPopper} className={reactionClassName} />
      <ReactionStat
        reaction={Bee.ReactionType.SMILING_FACE}
        count={smilingFace}
        className={twMerge("justify-end", reactionClassName)}
      />
      <ReactionStat reaction={Bee.ReactionType.STAR} count={star} className={reactionClassName} />
    </div>
  )
}

function ReactionStat({
  reaction,
  count,
  className,
}: {
  reaction: Bee.ReactionType
  count: number
  className?: string
}) {
  return (
    <div className={twMerge("flex items-center gap-3", className)}>
      <Bee.Reaction reaction={reaction} className="h-9 w-9" />
      <p className="text-gray-900">{count || 0}</p>
    </div>
  )
}
