import * as React from "react"

import { debounce } from "lodash-es"

import { PaginationBar } from "../PaginationBar"
import { TeamActionMenu } from "./TeamActionMenu"
import { appClient } from "~/src/lib/appClients"
import { PageMeta, pageMetaFromHeader } from "~/src/lib/page"

type Team = {
  name: string
  id: number
  members: number
  collections: number
  preferenceCollectors: number
  editPath: string
  deletePath: string
  isDefault: boolean
}

type TeamsData = { data: Array<Team> }

type TeamProps = {
  teams: TeamsData
  initialPageMeta: PageMeta
  teamsPath: string
  newTeamPath: string
}

export function TeamsTable({ teams: _teams, initialPageMeta, teamsPath, newTeamPath }: TeamProps) {
  const mounted = React.useRef(false)
  const [teams, setTeams] = React.useState(_teams.data)
  const [pageMeta, setPageMeta] = React.useState<PageMeta>(initialPageMeta)
  const [searchQuery, setSearchQuery] = React.useState(getSearchQuery())
  const setPage = (page: number) => {
    setPageMeta({
      ...pageMeta,
      currentPage: page,
    })
  }

  // identity stable debounced function
  const handleDebouncedSearch = React.useRef(
    debounce((e) => {
      setSearchQuery(e.target.value)
    }, 250)
  )

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      const controller = new AbortController()

      appClient
        .get<TeamsData>(`${teamsPath}.json?page=${pageMeta.currentPage}&search=${searchQuery}`, {
          signal: controller.signal,
        })
        .then((res) => {
          const teamsData = res.data
          setTeams(teamsData.data)
          setPageMeta(pageMetaFromHeader(res.headers))
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            // if the request was aborted, do nothing
            return
          }

          // re-throw error so it can be handled by logging
          throw err
        })

      return () => {
        controller.abort()
      }
    }
  }, [pageMeta.currentPage, searchQuery])

  return (
    <>
      <div className="columns small-12 action-bar row-center m-wrap flex">
        <div className="grow-1 small-12 search-bar-container">
          <form className="search-form" method="get">
            <input
              id="team-search"
              aria-label="Search teams"
              type="text"
              name="search"
              placeholder="Search by Name"
              autoComplete="off"
              onChange={handleDebouncedSearch.current}
              className="search-bar"
              defaultValue={getSearchQuery()}
            />
          </form>
          <a className="button" href={newTeamPath}>
            Create New Team
          </a>
        </div>
      </div>
      <div id="teams_table">
        <table className="large-12">
          <thead className="hide-for-small-down">
            <tr>
              <th className="team-name-title">Name</th>
              <th className="team-member-collection">Members</th>
              <th className="team-member-collection">Collection</th>
              <th className="team-member-collection">Preferred Gifts</th>
              <th className="team-member-collection">Actions</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => (
              <tr key={team.id} id={`team_row_${team.id}`}>
                <td className="team-name-data team-name-color">{team.name}</td>
                <td className="team-member-collection">{team.members}</td>
                <td className="team-member-collection">{team.collections}</td>
                <td className="team-member-collection">{team.preferenceCollectors}</td>
                <td className="team-member-collection">
                  <TeamActionMenu deletePath={team.deletePath} editPath={team.editPath} isDefault={team.isDefault} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {teams.length === 0 ? <div>No teams found...</div> : null}
        <PaginationBar setPage={setPage} {...pageMeta} perPage={teams.length} />
      </div>
    </>
  )
}

function getSearchQuery() {
  if (typeof window === "undefined") {
    return ""
  }
  const url = new URL(window.location.href)
  const search = url.searchParams.get("search")
  return search || ""
}
