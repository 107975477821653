import React, { useState } from "react"

import { ChevronDownIcon, ChevronUpIcon, CubeIcon } from "@heroicons/react/24/outline"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

import { summarizeSelections } from "../../lib/quickSend"
import { ON_DEMAND_SHIPPED_SEPARATELY_WARNING } from "../../lib/shippingRestrictions"
import { Bee } from "../BeeKit"
import { Description } from "~/src/components/ProductSelection/Description"
import { useSelectedItems, useSetSelectedItem } from "~/src/components/ProductSelection/FormPage"
import { QuickSendSummary } from "~/src/components/QuickSends/QuickSendSummary"
import { ShippingRestrictionsCue } from "~/src/components/ShippingRestrictionsCue"
import { QuickSendKind, QuickSendSelectionKind } from "~/src/types/preferredGift"
import {
  ProductSelectionItem,
  QuickSendSelectionItem,
  SelectableType,
  StoreProduct,
  Variant,
} from "~/src/types/productSelection"

type SummaryProps = {
  quickSendKind: QuickSendKind
  productFilter: (item: ProductSelectionItem) => boolean
}

export function QuickSendProductSelectionSummary(props: SummaryProps) {
  const { quickSendKind, productFilter } = props
  const userSelection = useSelectedItems<QuickSendSelectionItem>()
  const setSelected = useSetSelectedItem()
  const [isExpanded, expand] = useState<boolean>(false)

  const selectedStoreProducts = Object.values(userSelection).filter(productFilter)

  const expandIcons = () => {
    if (isExpanded) {
      return <ChevronDownIcon className="h-4 w-4" onClick={() => expand(false)} />
    } else {
      return <ChevronUpIcon className="h-4 w-4" onClick={() => expand(true)} />
    }
  }

  const onSelection = (storeProduct: StoreProduct, kind: QuickSendSelectionKind, selectedVariant?: Variant) => {
    const selection: ProductSelectionItem = {
      type: "QuickSend",
      id: userSelection[storeProduct.id]?.id,
      selectableId: storeProduct.id,
      selectableType: SelectableType.StoreProduct,
      selectable: { ...storeProduct, type: SelectableType.StoreProduct },
      selectedVariant: selectedVariant,
      kind,
    }

    setSelected(selection)
  }
  const { chosenSelections, giftedSelections, estimatedTotal } = summarizeSelections(selectedStoreProducts)

  const selectionsSection = () => {
    if (selectedStoreProducts.length) {
      return (
        <div className="md:p-4">
          <QuickSendSummary
            quickSendKind={quickSendKind}
            chosenSelections={chosenSelections}
            giftedSelections={giftedSelections}
            estimatedTotal={estimatedTotal}
            onSelection={onSelection}
            renderStoreProductDetails={(storeProduct) => {
              const { supportedRegions } = storeProduct
              return (
                <>
                  {storeProduct.onDemand && (
                    <Bee.Cue
                      className="italic text-gray-500"
                      tipProps={{ className: "not-italic text-black" }}
                      tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}
                    >
                      Ships separately
                    </Bee.Cue>
                  )}
                  {supportedRegions?.length ? (
                    <ShippingRestrictionsCue
                      regions={supportedRegions}
                      icon={<ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />}
                    />
                  ) : null}
                </>
              )
            }}
          />
        </div>
      )
    } else {
      return <p className="text-gray-500 md:p-4">No items have been selected yet</p>
    }
  }

  return (
    <div className="flex h-full w-full flex-col gap-2 border-y border-gray-300 px-6 py-4 text-gray-900 md:w-96 md:border-0 md:border-l md:border-gray-200 md:pl-6 md:pr-0">
      <h3 className="flex flex-row justify-between text-sm font-medium md:text-lg">
        <p>Items Selected: {selectedStoreProducts.length}</p>
        <div className="flex md:hidden">{expandIcons()}</div>
      </h3>
      <div
        className={`flex-col md:flex ${
          isExpanded ? "relative flex max-h-[50vh] overflow-y-auto" : "hidden"
        } gap-6 rounded-lg md:border md:border-gray-200`}
      >
        {selectionsSection()}
      </div>
      {quickSendKind == QuickSendKind.Standard ? (
        <div className="hidden md:flex">
          <Description />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
