export type PageMeta = {
  currentPage: number
  totalPages: number
  totalCount: number
}

export function pageMetaFromHeader(header): PageMeta {
  return {
    currentPage: Number(header["current-page"]),
    totalPages: Number(header["total-pages"]),
    totalCount: Number(header["total-count"]),
  }
}
