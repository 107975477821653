import React from "react"

import { CubeIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { ImageWithFallback } from "~/src/components"
import { identity } from "~/src/lib/any"

export type ThumbnailProps = {
  className?: string
  imgProps?: React.HTMLProps<HTMLImageElement>
  name: string
  src: string
  onFinal?: () => void
}

export function Thumbnail(props: ThumbnailProps) {
  const { name, className, src, onFinal, imgProps } = props

  return (
    <ImageWithFallback
      src={src}
      onFinal={onFinal}
      className={twMerge("h-14 w-14 overflow-hidden rounded-lg border border-gray-100", className)}
      imgProps={{
        className: "h-full w-full object-cover object-center",
        alt: [name, "image"].filter(identity).join(" "),
        ...imgProps,
      }}
    >
      <div className="flex h-full w-full items-center justify-center bg-gray-100 text-gray-400">
        <CubeIcon className="h-10 w-10 stroke-1" />
      </div>
    </ImageWithFallback>
  )
}
