import React from "react"

import { Menu } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"

import { Bee } from "~/src/components/BeeKit"

export type SignInProps = { signInUrl: string }

export function SignIn(props: SignInProps) {
  const { signInUrl } = props

  return (
    <>
      <div className="hidden flex-row items-center gap-6 md:flex">
        <a href={signInUrl} className="text-center text-sm">
          Sign in
        </a>
        <Bee.ButtonLink
          href="https://www.brilliantmade.com/get-started"
          className="border-none bg-magenta-500 text-sm text-white"
        >
          Get started
        </Bee.ButtonLink>
      </div>
      <Menu as="div" className="flex md:hidden">
        <Menu.Button>
          {({ open }) => (open ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />)}
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-10 flex w-full origin-top flex-col gap-1 border-b border-gray-200 bg-white p-4 shadow-lg">
          <Menu.Item>
            <Bee.ButtonLink
              href="https://www.brilliantmade.com/get-started"
              className="border-none bg-magenta-500 text-white"
            >
              Get started
            </Bee.ButtonLink>
          </Menu.Item>
          <Menu.Item>
            <Bee.ButtonLink kind="secondary" href={signInUrl} className="border-gray-200">
              Sign in
            </Bee.ButtonLink>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </>
  )
}
