import React, { useState } from "react"

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { format, parseISO } from "date-fns"
import { DateRange, DayPicker } from "react-day-picker"

import "react-day-picker/dist/style.css"

import style from "./StorefrontDateRangePicker.module.scss"
import { isNil } from "~/src/lib/any"

const FIRST_ORDER_YEAR = 2016

export type StorefrontDateRangePickerProps = {
  defaultValue?: { from?: string; to?: string }
  fromName?: string
  toName?: string
  placeholder: string
}

export function StorefrontDateRangePicker(props: StorefrontDateRangePickerProps) {
  const { defaultValue, placeholder, fromName, toName } = props

  const defaultFrom = defaultValue?.from ? parseISO(defaultValue?.from) : undefined
  const defaultTo = defaultValue?.to ? parseISO(defaultValue?.to) : undefined

  const [range, setRange] = useState<DateRange | undefined>({ from: defaultFrom, to: defaultTo })

  const from = range?.from ? format(range.from, "yyyy-MM-dd") : ""
  const to = range?.to ? format(range.to, "yyyy-MM-dd") : ""
  const selectedRangeDisplay = [from, to].join(" → ")

  return (
    <Popover className={style.base}>
      <PopoverButton as="div" className={style.selectedOption}>
        {isNil(range?.from) && isNil(range?.to) ? placeholder : selectedRangeDisplay}
      </PopoverButton>

      {typeof fromName === "string" ? <input type="hidden" name={fromName} value={from} /> : undefined}
      {typeof toName === "string" ? <input type="hidden" name={toName} value={to} /> : undefined}

      <PopoverPanel anchor="bottom start" className={style.popover}>
        <DayPicker
          captionLayout="dropdown"
          defaultMonth={range?.from}
          fromYear={FIRST_ORDER_YEAR}
          mode="range"
          onSelect={setRange}
          selected={range}
          toYear={new Date().getFullYear() + 1}
        />
      </PopoverPanel>
    </Popover>
  )
}
