import React, { useState } from "react"

import { Bee } from "./BeeKit"
import { ReactionType } from "./BeeKit/Reaction"
import { ParticleConfetti } from "~/src/components/ParticleConfetti"
import { ThankYouNoteForm } from "~/src/components/ThankYouNoteForm"
import { ThankYouNoteSummary } from "~/src/components/ThankYouNoteSummary"

const ANIMATION_DURATION_MS = 1300

export type ThankYouNoteLandingProps = {
  senderName: string
  senderEmail: string
  contactUsUrl: string
  brilliantUrl: string
  giftInformation: { url: string; name: string }[]
  submitPath: string
  viaRecipient: boolean
  notecardMessage: string
  preselectedReaction?: string
}

export function ThankYouNoteLanding(props: ThankYouNoteLandingProps) {
  const { viaRecipient, senderName, giftInformation, notecardMessage, preselectedReaction } = props

  const [reaction, setReaction] = useState<string>(preselectedReaction || "")
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  const onFormSubmitted = (e) => {
    if (reaction && !formSubmitted) {
      setFormSubmitted(true)
      e.preventDefault()
      e.stopPropagation()
      setTimeout(() => e.target.submit(), ANIMATION_DURATION_MS)
    }
  }

  return (
    <div className="p-0">
      <div className="h-full bg-gray-100 p-7 md:p-14">
        <div className="flex h-full flex-col md:flex-row">
          <section className="flex w-full basis-1/4 flex-col md:block">
            <ThankYouNoteSummary
              senderName={senderName}
              giftInformation={giftInformation}
              viaRecipient={viaRecipient}
              notecardMessage={notecardMessage}
            />
          </section>

          <section className="flex w-full basis-3/4 justify-items-center md:ml-5">
            <ThankYouNoteForm
              onReactionChanged={setReaction}
              onFormSubmit={onFormSubmitted}
              formSubmitted={formSubmitted}
              defaultReaction={preselectedReaction}
              {...props}
            />
          </section>
        </div>
        {reaction && formSubmitted && (
          <ParticleConfetti>
            <Bee.Reaction reaction={reaction as ReactionType} className={`h-14 w-14`} />
          </ParticleConfetti>
        )}
      </div>
    </div>
  )
}
