import React, { MouseEventHandler, ReactNode } from "react"

export type SelectableIconBoxProps = {
  value: string
  icon: ReactNode
  text: string
  name: string
  defaultChecked?: boolean
  onSelect?: MouseEventHandler<HTMLLabelElement>
}

export function SelectableIconBox(props: SelectableIconBoxProps) {
  const { name, value, icon, text, defaultChecked, onSelect } = props

  return (
    <div className="w-full">
      <input
        id={`${name}[${value}]`}
        type="radio"
        name={name}
        value={value}
        className="peer hidden"
        defaultChecked={defaultChecked}
      />
      <label
        htmlFor={`${name}[${value}]`}
        className="flex h-full w-full flex-col place-content-center items-center gap-4 rounded-lg border border-gray-300 p-4 peer-checked:border-gray-900 md:px-12 md:py-8"
        onClick={onSelect}
      >
        {icon}
        <p className="text-center text-gray-800">{text}</p>
      </label>
    </div>
  )
}
