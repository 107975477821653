import React from "react"

import { XCircleIcon } from "@heroicons/react/24/solid"
import { twMerge } from "tailwind-merge"

import { ImageSet } from "./ImageSet"
import { Format } from "~/src/lib/format"
import { RequestedOrderItem } from "~/src/types/requestedOrderItem"

export type RequestTileProps = {
  item: RequestedOrderItem
  onRemove?: (item: RequestedOrderItem) => void
  className?: string
}

export function RequestTile(props: RequestTileProps) {
  const {
    item,
    item: { name, quantity, subtotal, flavor, variantPhoto, isStoreProduct },
    onRemove,
    className,
  } = props
  const { mediumUrl, largeUrl } = variantPhoto ?? {}

  return (
    <div className={twMerge("flex w-full items-center gap-4 rounded-lg border bg-white p-4 md:flex-row", className)}>
      <div className="flex w-full gap-2 md:items-start">
        <ImageSet
          srcs={[mediumUrl, largeUrl]}
          className={twMerge("h-28 w-28 overflow-hidden rounded-lg object-cover object-center")}
        />

        <div className="flex grow flex-col gap-2">
          <hgroup className="flex flex-col">
            <h1 className="font-medium leading-6">{name}</h1>
            {isStoreProduct ? (
              <h2 className="text-sm text-gray-500">Order for Inventory Restock</h2>
            ) : (
              flavor && <h2 className="text-sm text-gray-500">{flavor}</h2>
            )}
          </hgroup>
          {!isStoreProduct ? (
            <div className="flex flex-col text-sm text-gray-500">
              <span>Quantity: {quantity}</span>
              <span>Subtotal: {Format.usd(subtotal)}</span>
            </div>
          ) : null}
        </div>

        {onRemove && (
          <div className="ml-auto flex items-start">
            <XCircleIcon
              className="h-6 w-6 cursor-pointer text-gray-300 transition-colors hover:text-red-500"
              onClick={() => onRemove(item)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
