import React, { MouseEventHandler, ReactNode } from "react"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

export type PaginationNavProps = {
  pageMeta: PageMeta
  setPage: (page: number) => void
}

export type PageMeta = {
  next: number | null
  prev: number | null
  page: number
  firstUrl: string
  lastUrl: string
  nextUrl: string
  prevUrl: string
  series: (string | number)[]
  scaffoldUrl: string
  count: number
  from: number
  to: number
}

export function PaginationNav(props: PaginationNavProps) {
  const { pageMeta, setPage } = props
  const { prev, next } = pageMeta

  const previousLink = prev ? (
    <BoxLink onClick={() => setPage(prev)} className="text-gray-500">
      <ChevronLeftIcon className="h-5 w-5" />
    </BoxLink>
  ) : (
    <BoxLink className="text-gray-300">
      <ChevronLeftIcon className="h-5 w-5" />
    </BoxLink>
  )
  const nextLink = next ? (
    <BoxLink onClick={() => setPage(next)} className="text-gray-500">
      <ChevronRightIcon className="h-5 w-5" />
    </BoxLink>
  ) : (
    <BoxLink className="text-gray-300">
      <ChevronRightIcon className="h-5 w-5" />
    </BoxLink>
  )

  return (
    <nav
      className="pagination flex gap-0 divide-x divide-solid rounded border bg-white text-xs font-semibold"
      role="navigation"
    >
      {previousLink}
      {pageMeta.series.map((item: number | string) => {
        if (typeof item == "number") {
          return (
            <BoxLink key={item} onClick={() => setPage(item)}>
              {item}
            </BoxLink>
          )
        } else if (item == "gap") {
          return <BoxLink key={item}>...</BoxLink>
        } else if (typeof item == "string") {
          return (
            <BoxLink key={item} className="bg-navy-800 text-white">
              {item}
            </BoxLink>
          )
        }
      })}
      {nextLink}
    </nav>
  )
}

function BoxLink({
  onClick,
  className,
  children,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  children?: ReactNode
}) {
  const linkContent = onClick ? (
    <button type="button" onClick={onClick}>
      {children}
    </button>
  ) : (
    children
  )

  return (
    <div className={twMerge("flex h-8 w-8 items-center justify-center no-underline", className)}>{linkContent}</div>
  )
}
