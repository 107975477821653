import { useEffect, type RefObject } from "react"

export enum MouseEventType {
  click = "click",
  contextmenu = "contextmenu",
  dblclick = "dblclick",
  mousedown = "mousedown",
  mouseenter = "mouseenter",
  mouseleave = "mouseleave",
  mousemove = "mousemove",
  mouseout = "mouseout",
  mouseover = "mouseover",
  mouseup = "mouseup",
}

export enum TouchEventType {
  touchcancel = "touchcancel",
  touchend = "touchend",
  touchmove = "touchmove",
  touchstart = "touchstart",
}

export type InteractionEventType = MouseEventType | TouchEventType

/**
 * Hook that triggers a callback when an interaction (i.e. mouse or touch) occurs outside of a specified element.
 *
 * @param ref A React ref object pointing to the element to detect interactions outside of.
 * @param onInteractOutside Optional callback function to be called when an interaction occurs outside of the specified element.
 * @param eventTypes An array of event types to listen for. Defaults to `click`, `dblclick`, and `touchstart`.
 *
 * @example
 * const ref = useRef(null);
 * useInteractOutside(ref, (event) => {
 *   console.log('Interacted outside:', event);
 * });
 */
export function useInteractOutside(
  ref: RefObject<HTMLElement>,
  onInteractOutside?: (event: MouseEvent) => void,
  eventTypes: InteractionEventType[] = [MouseEventType.click, MouseEventType.dblclick, TouchEventType.touchstart]
) {
  useEffect(() => {
    const handleInteractOutside = (event: MouseEvent) => {
      const path = event.composedPath()

      if (ref?.current && !path.includes(ref.current)) onInteractOutside?.(event)
    }

    eventTypes.forEach((eventType) => {
      document.addEventListener(eventType, handleInteractOutside, { capture: true })
    })

    return () => {
      eventTypes.forEach((eventType) => {
        document.removeEventListener(eventType, handleInteractOutside, { capture: true })
      })
    }
  }, [ref, onInteractOutside, eventTypes.join("_")])
}
