import React from "react"

import { FolderIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { useRequestCart } from "~/src/hooks/useRequestCart"

export function NavbarProjectFolder() {
  const { open, count } = useRequestCart()

  return (
    <div className="flex items-center">
      <div className="relative cursor-pointer" onClick={() => open()}>
        <span
          className={twMerge(
            "absolute -right-2 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-orange-500 text-xs font-bold text-white",
            count() <= 0 && "hidden"
          )}
        >
          {count()}
        </span>
        <FolderIcon className="h-6 w-6" />
      </div>
    </div>
  )
}
