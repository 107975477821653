import React from "react"

import { SubmitHandler, useForm } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"

export type ContactUsProps = {
  name?: string
  email?: string
  url: string
  brilliantUrl: string
  formClassName?: string
  onSubmit?: SubmitHandler<ContactUsForm>
}

type ContactUsForm = {
  name: string
  email: string
  message: string
}

export function ContactUs(props: ContactUsProps) {
  const { url, name, email, brilliantUrl, formClassName, onSubmit } = props

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ContactUsForm>({ defaultValues: { name, email } })

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-center text-3xl font-medium text-navy-800">Contact Brilliant</h1>
      <form
        className={twMerge(
          "grid grid-cols-1 gap-4 rounded-lg bg-white p-6 md:grid-cols-2 md:px-16 md:py-10",
          formClassName
        )}
        onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
        action={url}
        method="post"
      >
        <div className="flex w-full flex-col gap-1">
          <label className="text-sm font-medium text-gray-700">Name</label>
          <input
            className="rounded border border-gray-300"
            type="text"
            {...register("name", { required: { value: true, message: "required" } })}
          />
        </div>
        <div className="flex w-full flex-col gap-1">
          <label className="text-sm font-medium text-gray-700">Email address</label>
          <input
            className="rounded border border-gray-300"
            type="text"
            {...register("email", { required: { value: true, message: "required" } })}
          />
        </div>
        <div className="col-span-full flex w-full flex-col gap-1">
          <label className="text-sm font-medium text-gray-700">Message</label>
          <textarea
            className="rounded border border-gray-300"
            rows={5}
            {...register("message", { required: { value: true, message: "required" } })}
          ></textarea>
        </div>
        <Bee.Button className="col-span-full mt-2" disabled={!isValid}>
          Submit
        </Bee.Button>
      </form>
      <p className="text-center text-gray-400">
        Your shipment was sent by our partner,{" "}
        <a className="underline" href={brilliantUrl}>
          Brilliant
        </a>
      </p>
    </div>
  )
}
