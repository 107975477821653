import React, { useEffect, useState } from "react"

import { InformationCircleIcon } from "@heroicons/react/20/solid"
import { useFetcher } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { ProductSelection } from "."
import { Bee } from "~/src/components/BeeKit"
import { Image } from "~/src/components/Image"
import { Modal } from "~/src/components/Modal"
import { isntNil } from "~/src/lib/any"
import { Format } from "~/src/lib/format"
import { QuickSendSelectionKind } from "~/src/types/preferredGift"
import { Selectable, StoreProduct, Variant } from "~/src/types/productSelection"

/**
 * Modal for selecting a variant of a product.
 * @param selectable - The store product to select a variant for (for now, maybe also a store kit when we support that).
 * @param selectedVariant - The currently selected variant.
 * @param modalIsOpen - Whether the modal is open.
 * @param setModalIsOpen - Function to set the modal open state.
 * @param onChoice - Function to call when a variant is selected.
 * @param legacy - Whether to use the legacy variant fetching method - remove when we remove feature flag
 *  cx_quick_send_speed_up. Defaults to false. The legacy variant fetching method is to read from the storeProduct
 *  which is the `selectable` prop directly. The new method is to fetch the variants from the backend when this modal
 *  is opened.
 */
export type VariantDetailModalProps = {
  selectable: Selectable
  selectedVariant?: Variant
  modalIsOpen: boolean
  setModalIsOpen: (open: boolean) => void
  onChoice: (kind: QuickSendSelectionKind, selectedVariant?: Variant) => void
  legacy?: boolean // remove when we remove feature flag cx_quick_send_speed_up
}

export function VariantDetailModal({
  selectable,
  selectedVariant,
  modalIsOpen,
  setModalIsOpen,
  onChoice,
  legacy = false, // remove when we remove feature flag cx_quick_send_speed_up
}: VariantDetailModalProps) {
  const storeProduct = selectable as StoreProduct
  const { sizeCount } = storeProduct

  const [tempVariant, setTempVariant] = useState<Variant>(selectedVariant || ({} as Variant))

  let variants: Variant[] = []

  // this if clause along with the else block below are to be removed when we remove feature flag cx_quick_send_speed_up
  if (!legacy) {
    const path = `${selectable.id}/modal`
    const fetcher = useFetcher({ key: path })

    useEffect(() => {
      if (modalIsOpen && fetcher.state === "idle" && !fetcher.data) {
        fetcher.load(path)
      }
    }, [modalIsOpen, fetcher.state, fetcher.data, selectable.id])

    variants = fetcher.data?.variants ?? []
  } else {
    variants = storeProduct.variants
  }

  return (
    <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} className="bg-white md:h-[30rem]" usePortal={false}>
      <div className="flex flex-col items-center gap-2 bg-white">
        <div className="flex flex-col gap-4 md:flex-row">
          <Header selectable={selectable} className="flex gap-1 md:hidden" />
          <ProductImage variant={tempVariant} selectable={selectable} />
          <div className="flex flex-col gap-6">
            <Header selectable={selectable} className="hidden border-b border-gray-200 pb-6 md:flex" />

            <div className="flex flex-col gap-2">
              <SizeWarning sizeCount={sizeCount} />
              <ProductSelection.VariantDropdown
                variants={variants}
                selectedVariant={tempVariant}
                inventoryRequired={selectable?.inventoryRequired ?? true}
                onChange={setTempVariant}
                label={dropdownLabel(storeProduct)}
              />
            </div>
            <Bee.Button
              onClick={() => {
                setModalIsOpen(false)
                onChoice(QuickSendSelectionKind.Gifted, tempVariant)
              }}
              disabled={!tempVariant.id}
            >
              Add as included gift
            </Bee.Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

function ProductImage({ variant, selectable }: { variant: Variant; selectable: Selectable }) {
  const imageUrl = variant?.imageUrl || selectable.imageUrl

  return (
    <div className="flex place-content-center">
      <Image className="flex w-full md:w-96" src={imageUrl} />
    </div>
  )
}

function SizeWarning({ sizeCount }: { sizeCount: number }) {
  if (sizeCount > 1) {
    return (
      <div className="flex gap-2 rounded-lg bg-gray-100 p-2 md:w-80">
        <div className="h-4 w-4 pt-1">
          <InformationCircleIcon className="h-4 w-4 text-gray-400" />
        </div>
        <p className="text-sm text-gray-900">
          We don&apos;t recommend adding apparel items with size as an included gift since the recipient will not be
          able to select their preferred size.
        </p>
      </div>
    )
  }
}

function dropdownLabel(storeProduct: StoreProduct) {
  const { colorCount, flavorCount, sizeCount } = storeProduct

  const availableVariants = new Array<string>()
  if (colorCount > 1) {
    availableVariants.push("Color")
  }
  if (sizeCount > 1) {
    availableVariants.push("Size")
  }
  if (flavorCount > 1) {
    availableVariants.push("Flavor")
  }

  return availableVariants.join(" / ")
}

function Header({ selectable, className }: { selectable: Selectable; className?: string }) {
  const { name, displayPrice, customCurrencyUnit } = selectable

  return (
    <hgroup className={twMerge("flex flex-col gap-2", className)}>
      <h1 className="text-2xl font-medium text-gray-900">{name}</h1>
      <h2 className="text-lg text-gray-900">
        {isntNil(customCurrencyUnit)
          ? Format.customCurrency(displayPrice, customCurrencyUnit)
          : Format.usd(displayPrice)}
      </h2>
    </hgroup>
  )
}
