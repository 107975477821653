import React, { useEffect, useRef, useState } from "react"

import { XMarkIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import emptyProjectCartSrc from "~/images/empty_project_cart.png"
import { Bee } from "~/src/components/BeeKit"
import { RequestTile } from "~/src/components/RequestTile"
import { useInteractOutside } from "~/src/hooks/useInteractOutside"
import { useRequestCart } from "~/src/hooks/useRequestCart"
import { appClient } from "~/src/lib/appClients"
import { dataFlag } from "~/src/lib/jsx"
import { RequestedOrderItem } from "~/src/types/requestedOrderItem"

//region Types
export type RequestCartProps = {
  items?: RequestedOrderItem[]
  onRemoveItem?: (item: RequestedOrderItem) => void
  onSubmit?: () => void
  onClose?: () => void
  className?: string
}
//endregion

//region Component
export function RequestCart() {
  const [ready, setReady] = useState(false)
  const { setItems, items, isDirty, isOpen, dirty, clean, close } = useRequestCart()
  const cartRef = useRef<HTMLDivElement>(null)

  useInteractOutside(cartRef, () => close())

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isOpen) {
      appClient.post("/dashboard/estimate_request/track_event", { event: { name: "view_cart" } })
    }
  }, [isOpen])

  useEffect(() => {
    if (isDirty || (isOpen && !ready)) {
      fetchData()
    }
  }, [isOpen, isDirty])

  const fetchData = async () => {
    try {
      const { data } = await appClient(`/dashboard/estimate_request.json`, {
        headers: { Accept: "application/json" },
      })

      setItems(data?.items)
      setReady(true)
      clean()
    } catch (error) {
      // Prevent error from being thrown when the request is aborted which happens when the user
      // follows a URL to another page before the request is completed. It happens a lot 😓.
      if (error.name === "AxiosError" && error.code === "ECONNABORTED") return

      throw error
    }
  }

  const handleRemoveItem = async ({ id }: RequestedOrderItem) => {
    await appClient(`/dashboard/estimate_request/remove.json`, {
      method: "delete",
      params: { id },
      headers: { Accept: "application/json" },
    })

    dirty()
  }

  return (
    <div
      className={twMerge(
        "fixed bottom-0 right-0 top-0 z-60 flex w-[390px] flex-col bg-white shadow-lg transition",
        "translate-x-full duration-300 ease-in data-[open]:translate-x-0",
        "opacity-0 data-[open]:opacity-100"
      )}
      ref={cartRef}
      {...dataFlag(ready && isOpen, "open")}
    >
      <RequestCart_ items={items} onClose={() => close()} onRemoveItem={handleRemoveItem} />
    </div>
  )
}
//endregion

//region Private
function RequestCart_(props: RequestCartProps) {
  const { items = [], onRemoveItem, onSubmit, onClose, className } = props

  return (
    <>
      <div className="flex h-14 w-full shrink-0 justify-end border-b border-gray-200 bg-white">
        <button
          type="button"
          aria-label="Close Project Cart"
          className="flex h-full items-center px-3"
          onClick={onClose}
        >
          <XMarkIcon role="img" className="h-6 w-6" />
        </button>
      </div>

      {items.length === 0 ? (
        <EmptyCart className="p-4" />
      ) : (
        <>
          <div
            className={twMerge(
              "flex h-full flex-col gap-4 overflow-auto overscroll-contain bg-white p-4",
              "[mask-image:linear-gradient(to_top,transparent,black_theme(space.6))]",
              className
            )}
          >
            <p>In this estimate request:</p>

            <div className={twMerge("flex grow flex-col gap-4")}>
              {items.map((item: RequestedOrderItem) => {
                return <RequestTile key={item.id} item={item} onRemove={onRemoveItem} />
              })}
            </div>
          </div>

          <a className="flex shrink-0 bg-white px-4 pb-4" href="/dashboard/estimate_request">
            <Bee.Button className="w-full" onClick={onSubmit}>
              Review Request
            </Bee.Button>
          </a>
        </>
      )}
    </>
  )
}

function EmptyCart({ className }: { className?: string }) {
  return (
    <div className={twMerge("flex h-full flex-col items-center justify-center gap-4 text-center", className)}>
      <img src={emptyProjectCartSrc} alt="Empty Project Cart" className="h-32 w-32" />
      <hgroup className="max-w-[300px]">
        <h1 className="text-2xl font-medium">Your project cart is empty</h1>
        <p>Looks like you haven’t added anything to your project request yet.</p>
      </hgroup>
    </div>
  )
}
//endregion
