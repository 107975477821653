import * as React from "react"

import { KitItemFields } from "./KitItemFields"
import { StoreKit, StoreKitInstanceItem } from "~/src/serializedRecords"

interface KitItemsFieldGroups {
  kitItems: Record<number, StoreKitInstanceItem>
  storeKit: StoreKit
}

/*
 * Displays fields for all store products provided
 */
export function KitItemsFieldGroups({ kitItems, storeKit }: KitItemsFieldGroups) {
  if (storeKit.items.length === 0) return null

  // If any storeProduct has a photo key included we should render the column
  const shouldIncludePhotoColumn = !!storeKit.items.find((item) => Object.keys(item.storeProduct).includes("photo"))

  const items = storeKit.items.map((item) => {
    return (
      <KitItemFields
        key={item.id}
        kitItem={kitItems[item.id]}
        kitItemKey={item.id}
        storeProduct={item.storeProduct}
        includePhoto={shouldIncludePhotoColumn}
      />
    )
  })

  return <>{items}</>
}
