import React, { useRef } from "react"

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"

import { RailsForm } from "../RailsForm"
import style from "./TeamActionMenu.module.scss"
import useConfirmation from "~/src/hooks/useConfirmation"

export type TeamsActionsProps = {
  deletePath: string
  editPath: string
  isDefault: boolean
}

export function TeamActionMenu(props: TeamsActionsProps) {
  const { deletePath, isDefault, editPath } = props
  const formRef = useRef<HTMLFormElement>(null)
  const [confirm, ConfirmationDialog] = useConfirmation()
  const alertDescription =
    "Deleting a Team will remove the specific access of these collections for all listed users. Deleting a Team won't revoke store access for team members."

  const handleSubmit = async () => {
    const userconfirmed = await confirm("Are you sure?", alertDescription)
    if (userconfirmed) formRef.current?.submit()
  }

  return (
    <>
      <Menu>
        <MenuButton className={style.menuButton}>
          <i className="fa fa-ellipsis-v action-icon"></i>
        </MenuButton>

        <RailsForm ref={formRef} action={deletePath} method="delete" />

        <MenuItems anchor="left" unmount={false}>
          <div className={style.actionMenuPopover}>
            <MenuItem>
              <div className={style.actionMenuItem}>
                <a href={editPath} className={style.actionMenuButton}>
                  Edit
                </a>
              </div>
            </MenuItem>

            {!isDefault && (
              <MenuItem>
                <div className={style.actionMenuItem}>
                  <button className={style.actionMenuButton} onClick={handleSubmit} type="button">
                    Delete
                  </button>
                </div>
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Menu>
      <ConfirmationDialog />
    </>
  )
}
