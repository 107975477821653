import React, { useEffect, useState } from "react"

import { AdjustmentsHorizontalIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { createPortal } from "react-dom"
import { humanize } from "underscore.string"

import { getEmptyMessageTarget, toggleVisibility, Visibility } from "./productListingPage"
import { Bee } from "~/src/components/BeeKit"
import { isNil } from "~/src/lib/any"
import { DisplayTag, DisplayTagKind } from "~/src/types/displayTag"

function emptyMessage(visibility: Visibility, filterTags: string[]): string {
  if (filterTags.length > 0) {
    switch (visibility) {
      case "all":
        return "There are currently no products to show that match your filter criteria"
      case "favorites":
        return "You don't have any favorites right now that match your filter criteria"
    }
    // hidden
    return "You currently have nothing hidden that match your filter criteria"
  } else {
    switch (visibility) {
      case "all":
        return "There are no currently no products to show"
      case "favorites":
        return "You don't have any favorites right now"
    }
    // hidden
    return "You currently have nothing hidden"
  }
}

type LookbookProductFilterProps = {
  displayTags: DisplayTag[]
}

export function LookbookProductFilter(props: LookbookProductFilterProps) {
  let { displayTags } = props

  displayTags ??= []

  const config = displayTags.reduce((config, tag) => {
    if (isNil(config[tag.kind])) {
      switch (tag.kind) {
        case DisplayTagKind.SUSTAINABLE:
          config[tag.kind] = { label: "Sustainable", options: {} }
      }
    }

    config[tag.kind].options[tag.id] = tag.name
    return config
  }, {})

  const portalTarget = getEmptyMessageTarget()
  const [visibility, setVisibility] = useState<Visibility>("all")
  const [filterOpen, setFilterOpen] = useState(false)
  const [filterTags, setFilterTags] = useState<string[]>([])
  const [selections, setSelections] = useState<Bee.CheckBoxMultiCategorySelection[]>([])
  const [isEmpty, setIsEmpty] = useState(false)

  const selectedCategories = Bee.CheckBoxMultiCategorySelections.getCategoryName(selections)

  useEffect(() => {
    const toggleVisibilityCallback = () => {
      setIsEmpty(toggleVisibility(visibility, filterTags))
    }

    toggleVisibilityCallback()
    window.addEventListener("changeVisibility", toggleVisibilityCallback)
    return () => {
      window.removeEventListener("changeVisibility", toggleVisibilityCallback)
    }
  }, [visibility, filterTags])

  return (
    <div className="flex flex-col-reverse gap-4 px-8 py-6 sm:flex-row sm:items-center">
      {displayTags.length > 0 ? (
        <Bee.Tip open={filterOpen} onOpenChange={setFilterOpen} placement="bottom-start">
          <Bee.TipTrigger>
            <Bee.Button
              className="w-full border-gray-300 bg-white text-gray-600 sm:w-inherit"
              onClick={() => setFilterOpen(!filterOpen)}
              type="button"
            >
              <span className="flex items-center justify-center gap-2 text-sm">
                <span>Product Features</span>
                <AdjustmentsHorizontalIcon className="h-6 w-6" />
              </span>
            </Bee.Button>
          </Bee.TipTrigger>
          <Bee.TipContent className="z-[5]">
            <div className="w-[80vw] rounded-lg bg-white p-4 shadow sm:w-[200px]">
              <Bee.CheckBoxMultiCategorySelect
                selections={selections}
                config={config}
                style="checkbox"
                onChange={(selectedOptions) => {
                  setSelections(selectedOptions)
                  setFilterTags(selectedOptions.map(({ value }) => value))
                }}
              />
            </div>
          </Bee.TipContent>
        </Bee.Tip>
      ) : null}
      {selectedCategories.map((category) => (
        <Bee.Pill key={category} className="justify-space-between">
          <span>{humanize(category)}</span>
          <XMarkIcon
            className="m-0 h-4 w-4 self-center p-0 text-gray-400 hover:cursor-pointer"
            onClick={() => {
              const selectionsWithoutCategory = selections.filter((selection) => selection.category != category)
              setSelections(selectionsWithoutCategory)
              setFilterTags(selectionsWithoutCategory.map(({ value }) => value))
            }}
          />
        </Bee.Pill>
      ))}
      <ul className="flex gap-4 text-sm text-gray-700 sm:ml-auto">
        <li>
          <button
            type="button"
            className={visibility == "all" ? "font-medium" : ""}
            onClick={() => setVisibility("all")}
          >
            Show All
          </button>
        </li>
        <li>
          <button
            type="button"
            className={visibility == "favorites" ? "active font-bold" : ""}
            onClick={() => setVisibility("favorites")}
          >
            Favorites
          </button>
        </li>
        <li>
          <button
            type="button"
            className={visibility == "hidden" ? "active font-bold" : ""}
            onClick={() => setVisibility("hidden")}
          >
            Hidden
          </button>
        </li>
      </ul>
      {portalTarget && isEmpty
        ? createPortal(<div className="empty-search">{emptyMessage(visibility, filterTags)}</div>, portalTarget)
        : null}
    </div>
  )
}
