export enum StoreProductFieldType {
  file = "file",
  string = "string",
  text = "text",
}

interface ImageAttribute {
  url: string
}

export interface StoreProductPhoto {
  url: string
  xlarge: ImageAttribute
  large: ImageAttribute
  medium: ImageAttribute
  thumb: ImageAttribute
}

export type StoreProduct = {
  id: number
  name: string
  description: string
  availableVariantIds: number[]
  variants: Array<{ id: number; label: string }>
  fields: Array<StoreProductField>
  photo: StoreProductPhoto
}

export type StoreProductField = {
  id: number
  fieldType: StoreProductFieldType
  label: string
  limit?: number
  name: string
  sort: number
}
